import { RouteConfig } from 'vue-router';
import Layout from '@/components/layout/Layout.vue';

const subsMgmtRoutes: RouteConfig = {
  path: '/subscription-management',
  component: Layout,
  redirect: '/subscription-management/index',
  meta: {
    code: 'AUTHRSC_MOD_HOME',
  },
  children: [
    {
      path: 'index',
      component: () =>
        import(
          /* webpackChunkName: "subscriptionManagement" */ '@/views/subscriptionManagement/SubscriptionManagement.vue'
        ),
      name: 'subsMgmt',
      meta: {
        title: 'route.subsMgmt',
        icon: 'subsMgmt',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_HOME',
      },
    },
  ],
};

export default subsMgmtRoutes;
