
import { Component, Prop, Vue } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import CusFormItem from '@/components/form/CusFormItem.vue';
import CommFormInput from '@/components/form/CommFormInput.vue';
import { getOrgById } from '@/api/organizations';
import CommonBtn from '@/components/button/CommonBtn.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import { UserModule } from '@/store/modules/user';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { customFailedMessage } from '@/utils/prompt';
import { filterDeactivatedOrg } from '@/utils/index';
import { ErrorType } from '@/api/types';

interface ModuleAccType {
  resource: string;
}

@Component({
  name: 'NewUser',
  components: {
    Treeselect,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
    'module-access': ModuleAccess,
    'comm-form-input': CommFormInput,
  },
})
export default class extends Vue {
  @Prop({ required: true }) userForm!: any;
  @Prop() roles!: any;
  @Prop() userId!: string;
  @Prop() modulesInfo!: [];
  @Prop() errorInfos!: ErrorType[];

  /** Local variables */
  errorCode = ERROR_CODE_LIST;
  orgs: any = [];
  disabledTreeSel: boolean = false;

  get inlineMsg() {
    return false;
  }

  validateClaimsFtd = (rule: any, value: any, callback: any) => {
    const checkedNode = (this.$refs.moduleAccess as any).getCheckedNodes();
    if (checkedNode.length < 1) {
      callback(new Error(`${this.$t('userModule.tipSelectModuleAccess')}`));
    } else {
      callback();
    }
  };

  validateOrgId = (rule: any, value: any, callback: any) => {
    if (this.userForm.organizationId) {
      callback();
    } else {
      callback(new Error(`${this.$t('userModule.tipSelectOrg')}`));
    }
  };

  get rules() {
    const tmpRules = {
      email: [
        { required: true, message: this.$t('userModule.tipInputEmail') },
        {
          type: 'email',
          message: this.$t('userModule.tipInputCorrectEmail'),
          trigger: ['blur', 'change'],
        },
      ],
      role: [
        {
          required: true,
          message: this.$t('userModule.tipSelectRole'),
          trigger: 'change',
        },
      ],
      organizationId: [{ validator: this.validateOrgId, trigger: 'input' }],
      claimsFtd: [{ validator: this.validateClaimsFtd, trigger: 'change' }],
    };

    return tmpRules;
  }

  handleInput() {
    this.$emit('handle-input');
  }

  handleSelect() {
    (this.$refs.userForm as any).validateField('organizationId');
  }

  handleTreeselectOpen() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(-90deg)');
  }

  handleTreeselectClose() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(90deg)');
  }

  get errEmailInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.email)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('userModule.duplicateEmail')}`;
    } else if (
      this.errorInfos.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }

  changeRole(value: any) {
    this.roles.find((item: any) => {
      if (item.code === value) {
        this.$emit('change-role', item.claims);
      }
    });
  }

  customKey(node: any) {
    return {
      id: node.id,
      label: node.name,
      children: node.children?.length > 0 ? node.children : undefined,
    };
  }

  saveNewInfos() {
    let arr: ModuleAccType[] = [];
    (this.$refs.moduleAccess as any).getCheckedNodes().forEach((item: any) => {
      arr.push({
        resource: item.name,
      });
    });

    (this.$refs.userForm as any).validate((valid: any) => {
      if (valid) {
        this.$emit('handle-saveInfos', arr);
      } else {
      }
    });
  }

  async fetchOrgMgmtInfos() {
    await getOrgById(UserModule.organizationId).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        let tempResData = res.data;
        this.orgs = filterDeactivatedOrg([tempResData]);

        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  created() {
    this.fetchOrgMgmtInfos();
  }
}
