import { HEALTH_STATUS_COLOR, WarningType } from '@/utils/workData/lookuptable';

export enum HealthSearchType {
  healthFactor = 'name',
  // factorType = 'factorType',
  assetType = 'assetType',
  ifEnable = 'isEnabled',
  // normalCondition = 'normalCondition',
  // warningCondition = 'warningCondition',
  // alarmCondition = 'alarmCondition',
}

// This is a DUPLICATE of lookuptable.ts AssetType, except that type contains ASSTYP_ALL as well.
// This could be something to refactor.
export enum AssetTypes {
  TippingVehicles = 'ASSTYP_TIPPING_VEHICLES',
  MobileCompactor = 'ASSTYP_MOBILE_COMPACTOR',
  StaticCompactor = 'ASSTYP_STATIC_COMPACTOR',
  Cranes = 'ASSTYP_CRANES',
  SkipLoaders = 'ASSTYP_SKIP_LOADERS',
  WasteCollTrucks = 'ASSTYP_WASTE_COLL_TRUCKS',
  WasteBins = 'ASSTYP_WASTE_BINS',
  TableTopTissector = 'ASSTYP_TABLE_TOP_TISSECTOR',
}

export enum EnabledStatus {
  true = 'true',
  false = 'false',
}

export interface HealthStatus {
  name: string;
  color: HEALTH_STATUS_COLOR;
}

export interface HealthStatusItem {
  code: string;
  name: string;
  healthCondition: WarningType;
  healthConditionDescription: string;
  lastChangeTimestamp: Date;
  isErrorFlag: boolean;
}

export interface HealthStatusAsset {
  assetId: string;
  assetDataCollectionId: string;
  productModelPicturePath: string;
  productModelCode: string;
  productModelNumber: string;
  organizationId: string;
  organizationName: string;
  healthStatusLastChangeTimestamp: Date;
}
