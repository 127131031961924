import { RouteConfig } from 'vue-router';
import Layout from '@/components/layout/Layout.vue';

const assetMgmtRoutes: RouteConfig = {
  path: '/asset-mgmt',
  component: Layout,
  redirect: '/asset-mgmt/registerAssets',
  meta: {
    code: ['AUTHRSC_MOD_ASSETS_MGMT'],
  },
  children: [
    {
      path: 'index',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetManagement.vue'
        ),
      name: 'registeredAssets',
      meta: {
        title: 'route.assetMgmt.index',
        icon: 'assetMgmt',
        roles: ['admin'],
        code: ['AUTHRSC_MOD_ASSETS_MGMT', 'AUTHRSC_PAGE_REGISTERED_ASSETS'],
      },
    },
    {
      path: '/asset-mgmt/registeredAssets',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetManagement.vue'
        ),
      name: 'registeredAssets',
      meta: {
        title: 'route.assetMgmt.index',
        hidden: true,
        roles: ['admin'],
        code: [
          'AUTHRSC_MOD_ASSETS_MGMT',
          'AUTHRSC_PAGE_REGISTERED_ASSETS',
          'AUTHRSC_ACTION_ASSET_REGISTER',
        ],
      },
    },
    {
      path: '/asset-mgmt/connectedAssets',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetManagement.vue'
        ),
      name: 'connectedAssets',
      meta: {
        hidden: true,
        title: 'route.assetMgmt.index',
        roles: ['admin'],
        code: ['AUTHRSC_MOD_ASSETS_MGMT', 'AUTHRSC_PAGE_CONNECTED_ASSETS'],
      },
    },
    {
      path: '/asset-mgmt/assignedAssets',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/AssetManagement.vue'
        ),
      name: 'assignedAssets',
      meta: {
        hidden: true,
        title: 'route.assetMgmt.index',
        roles: ['admin'],
        code: ['AUTHRSC_MOD_ASSETS_MGMT', 'AUTHRSC_PAGE_ASSIGNED_ASSETS'],
      },
    },
    {
      path: 'registering-asset',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/register/RegisteringAsset.vue'
        ),
      name: 'assetMgmtRegisterAsset',
      meta: {
        title: 'route.assetMgmt.registerAsset',
        roles: ['admin'],
        code: [
          'AUTHRSC_MOD_ASSETS_MGMT',
          'AUTHRSC_MOD_ASSIGN_ASSET',
          'AUTHRSC_MOD_ASSET_VALUES',
          'AUTHRSC_ACTION_ASSET_CONNECT',
        ],
        hidden: true,
      },
    },
    {
      path: 'connect/edit/:assetOnBoardingId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/connect/EditConnectAsset.vue'
        ),
      name: 'assetMgmtConnectAssetEdit',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        roles: ['admin'],
        code: [
          'AUTHRSC_MOD_ASSETS_MGMT',
          'AUTHRSC_MOD_ASSIGN_ASSET',
          'AUTHRSC_MOD_ASSET_VALUES',
        ],
        hidden: true,
      },
    },
    {
      path: 'connect/view/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/connect/ConnectAssetView.vue'
        ),
      name: 'assetMgmtConnectView',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        roles: ['admin'],
        code: [
          'AUTHRSC_MOD_ASSETS_MGMT',
          'AUTHRSC_MOD_ASSIGN_ASSET',
          'AUTHRSC_MOD_ASSET_VALUES',
        ],
        hidden: true,
      },
    },
    {
      path: 'register/view/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/register/RegisterAssetView.vue'
        ),
      name: 'assetMgmtRegisterView',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        roles: ['admin'],
        code: [
          'AUTHRSC_MOD_ASSETS_MGMT',
          'AUTHRSC_MOD_ASSIGN_ASSET',
          'AUTHRSC_MOD_ASSET_VALUES',
        ],
        hidden: true,
      },
    },
    {
      path: 'register-connecting/view/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/register/RegisteredAssetConnectView.vue'
        ),
      name: 'assetMgmtRegisterConnectingView',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        roles: ['admin'],
        code: [
          'AUTHRSC_MOD_ASSETS_MGMT',
          'AUTHRSC_MOD_ASSIGN_ASSET',
          'AUTHRSC_MOD_ASSET_VALUES',
        ],
        hidden: true,
      },
    },
    {
      path: 'register/edit/:assetId',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/register/RegisterAssetEdit.vue'
        ),
      name: 'assetMgmtRegisterEdit',
      props: true,
      meta: {
        title: 'route.assetMgmt.index',
        roles: ['admin'],
        code: [
          'AUTHRSC_MOD_ASSETS_MGMT',
          'AUTHRSC_MOD_ASSIGN_ASSET',
          'AUTHRSC_MOD_ASSET_VALUES',
        ],
        hidden: true,
      },
    },
    {
      path: 'assign-asset',
      component: () =>
        import(
          /* webpackChunkName: "assetManagement" */ '@/views/assetManagement/connect/AssigningAsset.vue'
        ),
      name: 'assetMgmtAssignAsset',
      meta: {
        title: 'route.assetMgmt.assignAsset',
        roles: ['admin'],
        code: [
          'AUTHRSC_MOD_ASSETS_MGMT',
          'AUTHRSC_MOD_ASSIGN_ASSET',
          'AUTHRSC_MOD_ASSET_VALUES',
        ],
        hidden: true,
      },
    },
  ],
};

export default assetMgmtRoutes;
