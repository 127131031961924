import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import { RouteConfig } from 'vue-router';
import {
  constantRoutes,
  hyvaAdAsyncRoutes,
  hyvaCustAsyncRoutes,
} from '@/router';
import store from '@/store';
import { COMPANY_TYPE, UserRole } from '@/utils/workData/lookuptable';
import { UserModule } from '@/store/modules/user';
import { Claims } from './claims';
import { Subscription } from '@/api/subscriptionPackages';

// check resources required by page against resources the user has
export const equalOrContainsAny = (
  pageRes: string | string[] | undefined,
  userRes: string[]
): boolean => {
  if (!pageRes) return true;

  if (Array.isArray(pageRes)) {
    return pageRes.some((claim: any) => userRes.includes(claim));
  }

  return userRes.includes(pageRes);
};

export const equalOrContainsAnyClaim = (
  pageRes: string | string[] | undefined,
  claims: Claims
): boolean => {
  if (!pageRes) return true;

  if (Array.isArray(pageRes)) {
    return pageRes.some((claim) => claims.hasClaim(claim));
  }

  return claims.hasClaim(pageRes);
};

const hasPermission = async (
  roles: string[],
  route: RouteConfig,
  menus: string[],
  claims: Claims
) => {
  let allowed: boolean = true;

  // check for user claims
  allowed &&= equalOrContainsAnyClaim(route.meta?.claim, claims);

  // check for dashboard menu items
  allowed &&= equalOrContainsAny(route.meta?.code, menus);

  // check for system features
  if (route.meta?.systemFeature) {
    allowed &&= await UserModule.hasSystemFeature(route.meta?.systemFeature);
  }

  return allowed;
};

export const filterAsyncRoutes = async (
  routes: RouteConfig[],
  roles: string[],
  menus: string[],
  claims: Claims
) => {
  const res: RouteConfig[] = [];
  for (const route of routes) {
    const r = { ...route };
    if (await hasPermission(roles, r, menus, claims)) {
      if (r.children) {
        r.children = await filterAsyncRoutes(r.children, roles, menus, claims);
      }
      res.push(r);
    }
  }
  return res;
};

export interface IPermissionState {
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
}

export interface PermissionsConfig {
  companyType: COMPANY_TYPE;
  roles: string[];
  claims: Claims;
  menus: string[];
  subscriptions: Subscription[];
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = [];
  public dynamicRoutes: RouteConfig[] = [];

  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.routes = constantRoutes.concat(routes);
    this.dynamicRoutes = routes;
  }

  @Action
  public async GenerateRoutes(routerObj: PermissionsConfig) {
    let accessedRoutes;
    if (
      routerObj.companyType === COMPANY_TYPE.Hyva &&
      routerObj.roles.includes(UserRole.HyvaAdmin)
    ) {
      accessedRoutes = hyvaAdAsyncRoutes;
    } else {
      accessedRoutes = await filterAsyncRoutes(
        hyvaCustAsyncRoutes,
        routerObj.roles,
        routerObj.menus,
        routerObj.claims
      );
    }

    this.SET_ROUTES(accessedRoutes);
  }
}

export const PermissionModule = getModule(Permission);
