
import { unref } from 'vue';
import VueI18n, { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';

import { createGeofence } from '@/api/geofence';
import { GeofencePosition, TrackingDays } from '@/api/geofenceTypes';
import { Organization } from '@/api/organizations';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import { LoggedInUserRef, useLoggedInUser } from '@/composables/context';
import { UserModule } from '@/store/modules/user';
import { sortWeekDays } from '@/utils';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import {
  ASSET_TYPE_LIST,
  GEOFENCE_TYPE_CONFIG_LIST,
  HOURS_OF_THE_DAY,
} from '@/utils/workData/lookuptable';
import Treeselect from '@riophae/vue-treeselect';
import { checkFieldErrors, GeofenceError } from './helpers/checkFieldErrors';

interface TrackOption {
  id: string;
  value: string;
  label: VueI18n.TranslateResult;
}

interface CreateRequestBody {
  name: string;
  organizationId: string;
  geofencePosition: GeofencePosition[];
  associatedAssetTypes: string[];
  geofenceType: string;
  tracking: string;
  trackingStartTime?: string;
  trackingEndTime?: string;
  trackingDays?: TrackingDays[];
}

interface Normalizer {
  id: string;
  label: string;
  children: Organization[] | undefined;
}

@Component({
  name: 'CreateNewGeofence',
  components: {
    LeafletMap,
    Treeselect,
    BaseCard,
  },
})
export default class extends Vue {
  /** Local variables */
  geofenceForm = {
    name: '',
    organizationId: undefined,
    geofenceType: '',
    associatedAssetTypes: [],
    tracking: 'GFNTRACK_OFF',
    tracked: false,
    trackingStartTime: '',
    trackingEndTime: '',
    trackingDays: [],
    trackingInterval: '',
    trackingMode: '',
    geofencePosition: [],
  };
  organizations: Organization[] = [];
  geofenceOptions: any[] = GEOFENCE_TYPE_CONFIG_LIST;
  assetList: any[] = [];
  trackingModes: TrackOption[] = [
    {
      id: 'GFNTRACK_ALWAYS',
      value: 'GFNTRACK_ALWAYS',
      label: this.$t('geofence.trackAlways'),
    },
    {
      id: 'GFNTRACK_TIME_PERIOD',
      value: 'GFNTRACK_TIME_PERIOD',
      label: this.$t('geofence.trackTimePeriod'),
    },
  ];
  trackingOptions: TrackOption[] = [
    {
      id: 'GFNTRACK_INSIDE_TIME_INTERVAL',
      value: 'GFNTRACK_INSIDE_TIME_INTERVAL',
      label: this.$t('geofence.inTimeInterval'),
    },
    {
      id: 'GFNTRACK_OUTSIDE_TIME_INTERVAL',
      value: 'GFNTRACK_OUTSIDE_TIME_INTERVAL',
      label: this.$t('geofence.outTimeInterval'),
    },
    {
      id: 'GFNTRACK_ALL_DAYS',
      value: 'GFNTRACK_ALL_DAYS',
      label: this.$t('geofence.allDay'),
    },
  ];
  ArrayTime: string[] = HOURS_OF_THE_DAY;
  daysAvailable = Object.keys(TrackingDays);

  /** Map Config */
  geofenceConfig = {
    enableGeofence: true,
    enableCreate: true,
    editOnly: false,
  };
  center: number[] = [46.771211, 23.623634];
  zoom: number = 10;

  /** Other Data */
  errorInfos: GeofenceError[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  createNewGeofencePageIsLoading: boolean = false;
  rules = {
    name: [
      {
        required: true,
        message: this.$t('geofence.ApiErrorFieldInvalid.ApiFieldName'),
        trigger: 'change',
      },
    ],
  };

  loggedInUser!: LoggedInUserRef;

  $refs!: {
    map: LeafletMap;
  };

  /** CONTROL FUNCTIONS */
  async created() {
    this.loggedInUser = useLoggedInUser();
    this.assetList = (
      await Promise.all(
        ASSET_TYPE_LIST.map(async (listItem) =>
          listItem.claim && (await UserModule.hasMenu(listItem.claim))
            ? listItem
            : null
        )
      )
    ).filter((item) => item);
  }

  mounted() {
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    this.organizations = [user.organization];
    this.$refs.map.initializeGeofence([]);
  }

  /** VALIDATION */

  /**
   * Validate form errors
   * @param field
   */
  getErrorForField(field: string): TranslateResult | undefined {
    if (this.errorInfos.length === 0) {
      return;
    }

    let fieldErrors = this.errorInfos.filter((error) => error.field === field);
    if (fieldErrors.length === 0) {
      return;
    }

    return fieldErrors.map((error) => {
      let key = `geofence.${error.code}.${error.field}`;
      let errorMessage = this.$t(key);
      if (errorMessage != key) {
        return errorMessage;
      }
      return error.message;
    })[0]; // enough space to display only 1 error
  }

  /**
   * Get geofence coordinates
   */
  getGeoCoord(): void {
    // @ts-ignore
    this.geofenceForm.geofencePosition =
      this.$refs.map.getGeofenceCoordinates();
  }

  /**
   * Calculate tracking options
   */
  decideTracking(): void {
    if (!this.geofenceForm.tracked) {
      // remove all attributes because there is no tracking
      this.geofenceForm.trackingStartTime = '';
      this.geofenceForm.trackingEndTime = '';
      this.geofenceForm.trackingDays = [];
      this.geofenceForm.tracking = 'GFNTRACK_OFF';
    } else if (this.geofenceForm.trackingMode === 'GFNTRACK_ALWAYS') {
      this.geofenceForm.trackingStartTime = '';
      this.geofenceForm.trackingEndTime = '';
      this.geofenceForm.trackingDays = [];
      this.geofenceForm.tracking = 'GFNTRACK_ALWAYS';
    } else if (this.geofenceForm.trackingMode === 'GFNTRACK_TIME_PERIOD') {
      if (this.geofenceForm.trackingInterval === 'GFNTRACK_ALL_DAYS') {
        this.geofenceForm.trackingStartTime = '';
        this.geofenceForm.trackingEndTime = '';
      }
      this.geofenceForm.tracking = this.geofenceForm.trackingInterval;
    }
  }

  getRequestBody(): CreateRequestBody {
    this.errorInfos = [];
    this.getGeoCoord();
    this.decideTracking();
    checkFieldErrors(this.errorInfos, this.geofenceForm);
    const requestBody: CreateRequestBody = {
      name: `${this.geofenceForm.name}`,
      organizationId: `${this.geofenceForm.organizationId}`,
      geofencePosition: this.geofenceForm.geofencePosition,
      associatedAssetTypes: this.geofenceForm.associatedAssetTypes,
      geofenceType: this.geofenceForm.geofenceType,
      tracking: this.geofenceForm.tracking,
      //optional
      trackingStartTime: `${this.geofenceForm.trackingStartTime}`,
      trackingEndTime: `${this.geofenceForm.trackingEndTime}`,
      trackingDays: sortWeekDays(this.geofenceForm.trackingDays),
    };

    return requestBody;
  }

  /** REQUESTS */

  /**
   * Create geofence remotly through API
   */
  async postGeofence(): Promise<void> {
    try {
      this.createNewGeofencePageIsLoading = true;
      const requestBody = this.getRequestBody();
      const res = await createGeofence(requestBody);

      if (res.data?.errors) {
        this.errorInfos = res.data.errors;
      }

      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (!res.data.id) {
        customFailedMessage(this.$t('geofence.createError') as string);
        return;
      }

      customSuccessMessage(this.$t('geofence.create') as string);
      this.errorInfos = [];
      this.$router.push(
        `/administration/index?activeName=geofences&geofence=${res.data.id}`
      );
    } catch (exception) {
      return;
    } finally {
      this.createNewGeofencePageIsLoading = false;
    }
  }

  /**
   * Tree select normalizer key
   * @param node
   */
  customKeyNormalizer(node: Organization): Normalizer {
    return {
      id: node.id,
      label: node.name,
      children: node.children.length > 0 ? node.children : undefined,
    };
  }

  reloadPage(): void {
    this.$router.go(-1);
  }
}
