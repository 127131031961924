import { RouteConfig } from 'vue-router';
import Layout from '@/components/layout/Layout.vue';

const administration: RouteConfig = {
  path: '/administration',
  component: Layout,
  redirect: '/administration/index',
  meta: {
    code: ['AUTHRSC_MOD_ADMIN'],
  },
  children: [
    {
      path: 'index',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/Administration.vue'
        ),
      name: 'Administration',
      meta: {
        title: 'route.administration.index',
        icon: 'administration',
        roles: ['admin'],
        code: ['AUTHRSC_MOD_ADMIN'],
      },
    },
    {
      path: 'add-new-geofence',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/geofence/CreateNewGeofence.vue'
        ),
      name: 'createNewGeofence',
      meta: {
        roles: ['admin'],
        claim: 'AUTHRSC_ACTION_GEOFENCE_CREATE',
        hidden: true,
      },
    },
    {
      path: 'edit-geofence/:geofenceId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/geofence/EditGeofence.vue'
        ),
      props: true,
      meta: {
        title: 'route.administration.index',
        roles: ['admin'],
        claim: 'AUTHRSC_ACTION_GEOFENCE_UPDATE',
        hidden: true,
      },
    },
    {
      path: 'add-new-user',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/userMgmt/AddNewUser.vue'
        ),
      name: 'userDetails',
      meta: {
        title: 'route.administration.addNewUser',
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_USER_CREATE',
        hidden: true,
      },
    },
    {
      path: 'edit-user/:userId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/userMgmt/EditUser.vue'
        ),
      name: 'userEdit',
      props: true,
      meta: {
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_USER_EDIT',
        hidden: true,
      },
    },
    {
      path: 'view-user-info/:userId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/userMgmt/ViewUserInfo.vue'
        ),
      name: 'userDetails',
      props: true,
      meta: {
        title: 'route.administration.index',
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_ADMIN',
        hidden: true,
      },
    },
    {
      path: 'add-new-org',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/AddNewOrganization.vue'
        ),
      name: 'addNewOrganization',
      meta: {
        title: 'route.administration.addNewOrganization',
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_ORG_CREATE',
        hidden: true,
      },
    },
    {
      path: 'edit-org/:orgId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/AddNewOrganization.vue'
        ),
      name: 'organizationEdit',
      props: true,
      meta: {
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_ORG_EDIT',
        hidden: true,
      },
    },
    {
      path: 'add-new-helpdesk-org',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/AddNewHelpdeskOrganization.vue'
        ),
      name: 'helpdeskOrganization',
      meta: {
        title: 'route.administration.index',
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_ORG_CREATE',
        hidden: true,
      },
    },
    {
      path: 'edit-helpdesk-org/:orgId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/AddNewHelpdeskOrganization.vue'
        ),
      name: 'helpdeskOrganizationDetails',
      props: true,
      meta: {
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_ORG_EDIT',
        hidden: true,
      },
    },
    {
      path: 'view-org-info/:orgId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/ViewOrganizationInfo.vue'
        ),
      name: 'organizationDetails',
      props: true,
      meta: {
        title: 'route.administration.index',
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_ADMIN',
        hidden: true,
      },
    },
    {
      path: 'alarm-settings/edit',
      component: () =>
        import(
          /* webpackChunkName: "alarm" */ '@/views/alarm/components/AlarmConfigurationEditPage.vue'
        ),
      name: 'alarmConfigurationEditPageCustomer',
      meta: {
        title: 'route.alarmConfiguration',
        icon: 'alarmConfiguration',
        roles: ['admin'],
        claim: 'AUTHRSC_MOD_HOME',
        hidden: true,
      },
    },
  ],
};

export default administration;
