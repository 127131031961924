
import { Component, Prop, Vue } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import { getUserById, getModules, updateUser } from '@/api/users';
import {
  promptSuccessBox,
  promptFailedBox,
  customFailedMessage,
} from '@/utils/prompt';
import EditUserInfo from './components/EditUserInfo.vue';
import EditUserModuleAccess from './components/EditUserModuleAccess.vue';
import { PageModule } from '@/store/modules/page';
import { ErrorType } from '@/api/types';
import i18n from '@/lang';
import { omit } from 'lodash';

@Component({
  name: 'EditUser',
  components: {
    EditUserInfo,
    EditUserModuleAccess,
    NewCard,
  },
})
export default class extends Vue {
  @Prop({ required: true }) userId!: string;

  modulesInfo: any = [];
  defaultChecked: string[] = [];
  title: string = '';
  editUserFormIsLoading: boolean = false;
  userForm = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    roleName: '',
    organizationId: null,
    mobilePhone: '',
    note: '',
    claimsFtd: [''],
    claims: [],
  };

  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  activeName: string = 'first';
  visiable: boolean = false;
  dialogContent: any = '';

  created() {
    this.fetchModules();
    this.fetch();
  }

  handleCancel() {
    this.visiable = false;
  }

  handleClick(tab: any, event: any): void {}

  async saveUserInfo() {
    const newValues = omit(this.userForm, 'claims');

    await updateUser(this.userId, newValues).then((res) => {
      if (res.code === 200) {
        this.errorInfos = [];
        promptSuccessBox(this.$t('common.save') as string);
        this.$router.push('/administration/index');
      } else if (res.code === 400) {
        promptFailedBox(this.$t('common.save') as string);
        this.errorInfos = res.data.errors;
      } else if (res.code === 500) {
        this.$message({
          message: this.$t('userModule.saveFailedrights').toString(),
          type: 'error',
        });
        return;
      }
    });
  }

  async fetchModules() {
    await getModules().then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.modulesInfo = res.data;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  async fetch() {
    try {
      this.editUserFormIsLoading = true;
      await getUserById(this.userId).then((res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          // this.userForm = res.data;
          Object.assign(this.userForm, res.data);
          const title = i18n.t('common.editInterpolated', {
            name: this.userForm.username,
          });
          PageModule.setTitle(title);

          const rootMenus = [
            'AUTHRSC_MOD_ADMIN',
            'AUTHRSC_MOD_ORGMNG',
            'AUTHRSC_MOD_USERMNG',
          ];
          let arr: string[] = [];

          this.userForm.claims.forEach((item: any) => {
            if (!rootMenus.includes(item.resource)) arr.push(item.resource);
          });

          this.userForm.claimsFtd = arr;
          return;
        }

        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.editUserFormIsLoading = false;
    }
  }
}
