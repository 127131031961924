import { UserModule } from '@/store/modules/user';

/** General options for filtering */
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = UserModule.gridPageSize;

/** Filtering mapping references */
export const COMPANY_ID = 'companyId';
export const ORGANIZATION_ID = 'organizationId';
export const SERVICE_REGION = 'serviceRegion';
export const ASSET_CONNECT_STATUS_FIELD = 'connectStatus';
export const ASSET_CONNECTION_STATUS_FIELD = 'connectionStatus';
export const CREATED_ON = 'createdOn';
export const ACCESS_TIME = 'accessTime';
