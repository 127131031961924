
import { Component, Prop, Vue } from 'vue-property-decorator';
import { deleteGeofence } from '@/api/geofence';
import { Geofence, TrackingDays } from '../../../../api/types';
import { sortWeekDays } from '@/utils';
import { GeofenceStatusType, geofenceTypeColorMapping } from './data';
import { GEOFENCE_TRACKING_TYPE } from '@/utils/workData/lookuptable';

@Component({
  name: 'GeofenceCard',
})
export default class extends Vue {
  @Prop({ required: true }) geofenceId!: string;
  @Prop() geofence!: Geofence;
  @Prop() selectedId!: string;

  expanded: boolean = false;
  status: string = 'info';
  statuses = [
    {
      key: GeofenceStatusType.Active,
      value: 'success',
    },
    {
      key: GeofenceStatusType.Draft,
      value: 'warning',
    },
    {
      key: GeofenceStatusType.Deprecated,
      value: 'info',
    },
  ];
  statusType = GeofenceStatusType;

  created() {
    const status = this.statuses.find((s) => s.key === this.geofence.lifeCycle);
    this.status = status ? status.value : 'info';
    this.expanded = this.selectedId === this.geofenceId;
  }

  close() {
    this.$emit('delete');
  }

  editGeo() {
    this.$emit('edit');
  }

  updated() {
    this.expanded = this.selectedId === this.geofenceId;
  }

  get showTrackingDays() {
    return [
      GEOFENCE_TRACKING_TYPE.AllDays,
      GEOFENCE_TRACKING_TYPE.InsideTimeInterval,
      GEOFENCE_TRACKING_TYPE.OutSideTimeInterval,
    ].includes(this.geofence.tracking.toString());
  }

  get showTrackingInterval() {
    return [
      GEOFENCE_TRACKING_TYPE.InsideTimeInterval,
      GEOFENCE_TRACKING_TYPE.OutSideTimeInterval,
    ].includes(this.geofence.tracking.toString());
  }

  get geofenceTrackingDays(): TrackingDays[] {
    return sortWeekDays(this.geofence.trackingDays);
  }

  async deleteGeo() {
    await deleteGeofence(this.geofence.id);
  }

  get geofenceTypeStyle() {
    let color = geofenceTypeColorMapping.get(this.geofence.geofenceType);
    if (!color) return undefined;
    return { backgroundColor: color };
  }
}
