import {
  ColumnRendering,
  ColumnRenderType,
} from '@/utils/types/columnCustomizationTypes';
import { Position } from '@/utils/types/geoposition';
import { UI_COLUMN_CODES } from './lookuptable';
import { formatTime } from '@/utils/time';

// Common column customizations. Defined your own specific customizations if you're using them more than a few times
const CommonCustomizations = {
  GenericTranslatable: { type: ColumnRenderType.Translatable },
  NumberToFixed: {
    type: ColumnRenderType.Generic,
    getValue: (value: any) => {
      return (parseFloat(value) || 0).toFixed(3);
    },
  },
  FormattedDuration: {
    type: ColumnRenderType.Generic,
    getValue: (seconds: number | undefined) => {
      if (!seconds) return '';

      return formatTime(seconds);
    },
  },
  LargerGeneric: { type: ColumnRenderType.Generic, width: 230 },
  SmallerGeneric: { type: ColumnRenderType.Generic, width: 100 },
  NoSort: { type: ColumnRenderType.Generic, sortable: false },
};

export const TRIPS_TABLE_CUSTOMIZATION: Partial<
  Record<UI_COLUMN_CODES, ColumnRendering>
> = {
  UICOL_TRIP_STATUS: CommonCustomizations.GenericTranslatable,
  UICOL_TRIP_END_DATETIME: {
    type: ColumnRenderType.DateTime,
    sortField: 'endPoint.timestamp',
  },
  UICOL_TRIP_START_DATETIME: {
    type: ColumnRenderType.DateTime,
    sortField: 'startPoint.timestamp',
  },
  UICOL_TRIP_START_POSITION: {
    type: ColumnRenderType.Generic,
    getValue: (a: Position) => {
      return a.addressLabel || [a.street, a.city, a.country].join(' ');
    },
    sortField: 'startPoint.addressLabel',
  },
  UICOL_TRIP_END_POSITION: {
    type: ColumnRenderType.Generic,
    getValue: (a: Position) => {
      return a.addressLabel || [a.street, a.city, a.country].join(' ');
    },
    sortField: 'endPoint.addressLabel',
  },
  UICOL_TRIP_SUMMARY: {
    type: ColumnRenderType.Component,
    getComponent: () => import('@/components/table/cells/TimelineCell.vue'),
    width: 525,
    sortable: false,
    overflow: false,
  },
  UICOL_TRIP_TIME: CommonCustomizations.FormattedDuration,
  UICOL_TRIP_TIPPING_TIME: {
    ...CommonCustomizations.FormattedDuration,
    hideKpiUnit: true,
  },
  UICOL_TRIP_MILEAGE: { ...CommonCustomizations.NumberToFixed },
  UICOL_TRIP_MAX_TIPPING_ANGLE: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_MAX_SIDELOAD_ANGLE: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_HAS_SIDELOAD_DANGER_WARNING: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_MAX_PRESSURE_OIL_CYLINDER: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_MAX_PRESSURE_RETURN_FILTER: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_PAYLOAD: { type: ColumnRenderType.Generic },
  UICOL_TRIP_AVERAGE_SPEED: { ...CommonCustomizations.NumberToFixed },
  UICOL_TRIP_BEFORE_TIPPING_PAYLOAD: { ...CommonCustomizations.NumberToFixed },
  UICOL_TRIP_LEFTOVER_PAYLOAD: { ...CommonCustomizations.NumberToFixed },
  UICOL_TRIP_TIPPED_PAYLOAD: { ...CommonCustomizations.NumberToFixed },
  UICOL_TRIP_ID: { ...CommonCustomizations.LargerGeneric },
};

export const OPERATION_LOG_TABLE_CUSTOMIZATION: Partial<
  Record<UI_COLUMN_CODES, ColumnRendering>
> = {
  UICOL_OPERATION_LOG_ICON: {
    ...CommonCustomizations.SmallerGeneric,
    type: ColumnRenderType.Component,
    getComponent: () => import('@/components/table/cells/IconCell.vue'),
    getComponentProperties: (row) => ({
      icon: { name: row.severityCode, label: row.severityName },
    }),
  },
  UICOL_OPERATION_LOG_ORGANIZATION: {
    ...CommonCustomizations.SmallerGeneric,
    sortable: true,
  },
  UICOL_OPERATION_LOG_TIMESTAMP: {
    ...CommonCustomizations.SmallerGeneric,
    type: ColumnRenderType.DateTime,
  },
  UICOL_OPERATION_LOG_EVENT_TYPE: {
    ...CommonCustomizations.SmallerGeneric,
    sortable: true,
  },
  UICOL_OPERATION_LOG_EVENT_NAME: {
    ...CommonCustomizations.SmallerGeneric,
    sortable: true,
  },
  UICOL_OPERATION_LOG_EVENT_DESCRIPTION: {
    ...CommonCustomizations.LargerGeneric,
    sortable: true,
  },
};
