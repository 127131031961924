
import { Component, Prop, Vue } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import NewCard from '@/components/cusCard/NewCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import CommonBtn from '@/components/button/CommonBtn.vue';
import { UserModule } from '@/store/modules/user';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { getOrgById } from '@/api/organizations';
import { customFailedMessage } from '@/utils/prompt';
import { ErrorType } from '@/api/types';
import { filterDeactivatedOrg } from '@/utils/index';
import { Organization } from '@/api/organizations';

@Component({
  name: 'userSetting',
  components: {
    Treeselect,
    'new-card': NewCard,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop() id!: string;
  @Prop() userForm!: any;
  @Prop() errorInfo!: ErrorType[];

  /** Local variables */
  userInfo: any = {};
  title: string = '';
  userId = UserModule.id;
  orgs: Organization[] = [];

  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  created() {
    this.fetchOrgMgmtInfos();
  }

  /**
   * Get inline message
   */
  get inlineMsg(): boolean {
    return false;
  }

  get errNameInfo() {
    let errInfo: string = '';

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldUnique'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }

  get rules() {
    const tmpRules = {
      username: [
        {
          required: true,
          message: this.$t('userModule.tipInputUsername'),
          trigger: 'change',
        },
      ],
    };

    return tmpRules;
  }

  /**
   * Send user form event to the parent
   */
  saveUserInfo(): void {
    (this.$refs.userForm as any).validate((valid: any) => {
      if (valid) {
        this.$emit('handle-saveInfos');
      }
    });
  }

  customKey(node: Organization) {
    return {
      id: node.id,
      label: node.name,
      children: node.children?.length > 0 ? node.children : undefined,
    };
  }

  /**
   * Fetch organization by id
   */
  async fetchOrgMgmtInfos(): Promise<void> {
    try {
      const response = await getOrgById(UserModule.organizationId);
      this.orgs = filterDeactivatedOrg([response.data]);
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
    }
  }
}
