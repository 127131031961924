import { GeoPosition, Position } from '@/utils/types/geoposition';
import {
  AssetType,
  TripStatus,
  TripSubActivity,
} from '@/utils/workData/lookuptable';
import { KpiData } from './columnCustomizationTypes';

export enum SearchType {
  organizationName = 'organizationName',
  tripStatus = 'tripStatus',
  startPosition = 'startPoint.addressLabel',
  endPosition = 'endPoint.addressLabel',
}

export interface TripTrack {
  timestamp: Date;
  location: Position;
}

export interface TripEvent {
  id: string;
  severityCode: string;
  eventType: string;
  eventName: string;
  eventDescription: string;
  eventTimestamp: Date;
  location: GeoPosition;
}

export interface TripActivity {
  subActivityKind: TripSubActivity;
  subActivityStartTimeStamp: Date;
  location: GeoPosition;
}

export interface Metric {
  value: number;
  unit: string;
}

export interface Trip {
  id: string;
  companyAssetId: string;
  assetType: AssetType;
  organizationId: string;
  organizationName: string;
  timeZone: string;

  tripStatus: TripStatus;
  startTime: Date;
  startPosition: Position;
  endTime?: Date;
  endPosition: Position | string;
  tripTime: string | number;

  summary: TripActivity[];
  events: TripEvent[];
  kpiData: KpiData[];
  payload: Metric;

  sideloadWarning?: string;
  sideloadDangerWarning?: string;
  overloadWarning?: string;
  bucklingWarning?: string;
  isBlackBoxDataAvailable?: boolean;
  blackBoxFilePath?: string;
}
