
import { Component, Prop, Vue } from 'vue-property-decorator';
import CommonBtn from '@/components/button/CommonBtn.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';

interface ModuleAccType {
  resource: string;
}

@Component({
  name: 'EditUserModuleAccess',
  components: {
    'common-btn': CommonBtn,
    'module-access': ModuleAccess,
  },
})
export default class extends Vue {
  @Prop() modulesInfo!: [];
  @Prop() defaultChecked!: string[];

  saveUserModuleAccess() {
    let arr: ModuleAccType[] = [];
    (this.$refs.moduleAccess as any).getCheckedNodes().forEach((item: any) => {
      arr.push({
        resource: item.name,
      });
    });

    this.$emit('handle-save-module-access', arr);
    // (this.$refs.userForm as any).validate((valid: any) => {
    //     if(valid)
    //     {
    //         this.$emit('handle-save-module-access', arr);
    //     }
    //     else
    //     {
    //     }
    // });
  }
}
