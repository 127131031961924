
import { getUsers } from '@/api/users';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  flattenOrganizations,
  LoggedInUserRef,
  useMutableLoggedInUser,
} from '@/composables/context';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage } from '@/utils/prompt';
import {
  ACTIVATION_STATUS_LIST,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import {
  USER_COLS,
  USER_MGMT_TYPES,
  USER_SEARCH_FIELDS,
} from '@/utils/workData/userMgmt';
import { isUndefined } from 'lodash';
import moment from 'moment';
import { isNativeError } from 'util/types';
import { unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'UserManagement',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  path: string = 'view-user-info';
  userTypes = USER_MGMT_TYPES;
  activationStatus = ACTIVATION_STATUS_LIST;
  total: number = 0;
  totalUsers: number = 0;
  listLoading: boolean = false;
  list = [];
  cols = USER_COLS;
  pageSize = UserModule.gridPageSize;
  currentPage = 1;
  isUserManagementLoading: boolean = false;
  userSearchFields = USER_SEARCH_FIELDS;
  testValue: string = '';
  loadingText: string = this.$t('userModule.loadingUserData') as string;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  hasCreateNewUserFeature: boolean = false;

  loggedInUser!: LoggedInUserRef;

  private defaultRequestParameter: string = 'companyId';

  async created() {
    this.loggedInUser = useMutableLoggedInUser().ref;
    this.hasCreateNewUserFeature = await UserModule.hasSystemFeature(
      SYSTEM_FEATURES.CreateUser
    );
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      1,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue =
      searchParams.value && searchParams.reference != 'createdDate'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'createdDate'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;
    let finalUrl = '';

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);

    const user = unref(this.loggedInUser);
    finalUrl += `&companyId=${user?.companyId}`;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) finalUrl += `&sortBy=${sortBy}&order=${order}`;

    return finalUrl;
  }

  fetchUsersDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  fetchUserManagementBySearchParameters(col: string, order: string) {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchUsersDataBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'claims') return; // need IoT implementation !
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  async fetchUserManagementData(filter: string) {
    this.isUserManagementLoading = true;
    await getUsers(filter).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        let resData = res.data;
        resData.users.forEach((item: any) => {
          item.emailVerified = item.emailVerified
            ? 'userModule.yes'
            : 'userModule.no';
        });

        this.total = resData.total;
        this.list = resData.users.filter((user: any) =>
          flattenOrganizations(
            unref(this.loggedInUser)?.organization ?? []
          ).some((org) => org.id === user.organizationId)
        );

        if (
          typeof this.list != 'undefined' &&
          this.list != null &&
          this.list.length != null
        ) {
          this.totalUsers = this.list.length;
        }
        this.isUserManagementLoading = false;
      }

      if (res.code === 500) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      }

      this.isUserManagementLoading = false;
    });
  }
}
