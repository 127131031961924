export const USER_COLS = [
  {
    label: 'userModule.userName',
    prop: 'username',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.firstName',
    prop: 'firstName',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.lastName',
    prop: 'lastName',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.organizationName',
    prop: 'organizationName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.userRole',
    prop: 'roleName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.email',
    prop: 'email',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.mobilePhone',
    prop: 'mobilePhone',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.createdDate',
    prop: 'createdOn',
    required: false,
    visible: true,
    sortable: 'custom',
    dateFormat: 'date',
  },
  {
    label: 'userModule.lastLoginTime',
    prop: 'lastLoginTime',
    required: false,
    visible: true,
    sortable: 'custom',
    dateFormat: 'datetime',
  },
  {
    label: 'userModule.moduleAccess',
    prop: 'claims',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.activationStatus',
    prop: 'activationStatus',
    required: false,
    visible: true,
    sortable: 'custom',
    multiLang: true,
  },
  {
    label: 'userModule.emailVerified',
    prop: 'emailVerified',
    required: false,
    visible: false,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'userModule.language',
    prop: 'i18nCode',
    required: false,
    visible: false,
    sortable: 'custom',
  },
  {
    label: 'userModule.note',
    prop: 'note',
    required: false,
    visible: false,
    sortable: 'custom',
  },
];

export const USER_SEARCH_FIELDS = [
  {
    label: 'userModule.userName',
    prop: 'username',
  },
  {
    label: 'userModule.firstName',
    prop: 'firstName',
  },
  {
    label: 'userModule.lastName',
    prop: 'lastName',
  },
  {
    label: 'userModule.organizationName',
    prop: 'organizationName',
  },
  {
    label: 'userModule.userRoleName',
    prop: 'roleName',
  },
  // {
  //     label: 'userModule.companyType',
  //     prop: 'companyType',
  // },
  {
    label: 'userModule.email',
    prop: 'email',
  },
  {
    label: 'userModule.mobilePhone',
    prop: 'mobilePhone',
  },
  {
    label: 'userModule.createdDate',
    prop: 'createdDate',
  },
  {
    label: 'userModule.activateStatus',
    prop: 'activationStatus',
  },
  // {
  //     label: 'userModule.lastLoginTime',
  //     prop: 'lastLoginTime',
  // },
  // {
  //     label: 'userModule.moduleAccess',
  //     prop: 'claims',
  // },
  // {
  //     label: 'userModule.activationStatus',
  //     prop: 'activationStatus',
  // },
  // // {
  // //     // api original data
  // //     label: 'userModule.emailVerified',
  // //     prop: 'emailVerified',
  // //     required: false,
  // //     visible: false,
  // // },
  // {
  //     label: 'userModule.emailVerified',
  //     prop: 'userVerified',
  // },
  // {
  //     label: 'userModule.language',
  //     prop: 'i18nCode',
  // },
  // {
  //     label: 'userModule.note',
  //     prop: 'note'
  // }
];

export const USER_ROLE_MANAGEMENT = [
  {
    label: 'userRoleModule.userRoleName',
    prop: 'name',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userRoleModule.customerType',
    prop: 'companyType',
    required: true,
    visible: true,
    sortable: 'custom',
    multiLang: true,
  },
  {
    label: 'userRoleModule.moduleAccess',
    prop: 'claims',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'userRoleModule.note',
    prop: 'note',
    required: false,
    visible: true,
    sortable: 'custom',
  },
];

export const USER_ROLE_MANAGEMENT_SEARCH_OPTIONS = [
  {
    label: 'userRoleModule.userRoleName',
    prop: 'name',
  },
  {
    label: 'userRoleModule.customerType',
    prop: 'companyType',
  },
];

export const USER_MGMT_TYPES = [
  { id: 1, value: 'Fleet Manager' },
  { id: 2, value: 'Euqipment Manager' },
  { id: 3, value: 'Driver Manager' },
  { id: 4, value: 'Monitor' },
];
