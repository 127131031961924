
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { Organization } from '@/api/organizations';
import { ErrorType } from '@/api/types';
import CommonBtn from '@/components/button/CommonBtn.vue';
import {
  ACTIVATION_STATUS,
  COUNTRY_TYPE_LIST,
} from '@/utils/workData/lookuptable';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { useLoggedInUser } from '@/composables/context';
import { unref } from 'vue';
import { flattenOrganizations } from '@/composables/context';
import { UserModule } from '@/store/modules/user';
import { getTimezones, TimezoneResponse } from '@/api/timezone';

export interface OrgForm {
  name: string;
  parentId: string | undefined;
  parentName: string;
  street: string;
  district: string;
  province: string;
  city: string;
  country: string;
  zipCode: string;
  timezone: string;
  companyId: string;
  activationStatus: ACTIVATION_STATUS;
  note: string;
}

@Component({
  name: 'NewOrg',
  components: {
    Treeselect,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop({ required: true }) orgForm!: OrgForm;
  @Prop({ required: true }) disabledTreeSel!: boolean;
  @Prop({ required: false }) saveOrCreate!: boolean;
  @Prop() errorInfos!: ErrorType[];
  @Prop() orgId!: string;

  orgInfos: Organization[] = [];
  countries = COUNTRY_TYPE_LIST;
  errorCode = ERROR_CODE_LIST;
  timeZoneOptions: TimezoneResponse[] = [];
  placeHolderOrgForm: OrgForm = {
    name: '',
    parentId: this.orgForm.parentId,
    parentName: this.orgForm.parentName,
    street: '',
    district: '',
    province: '',
    city: '',
    country: '',
    zipCode: '',
    timezone: '',
    companyId: UserModule.companyId,
    activationStatus: ACTIVATION_STATUS.Activated,
    note: '',
  };

  @Watch('orgForm')
  handleData(newData: any) {
    this.placeHolderOrgForm = newData;
    return newData;
  }

  validateParentId = (rule: any, value: any, callback: any) => {
    if (!this.placeHolderOrgForm.parentId) {
      callback(new Error(`${this.$t('organizationModule.selectOrg')}`));
    }
    callback();
  };

  get inlineMsg() {
    return false;
  }

  get rules() {
    const rules = {
      name: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputOrgName'),
          trigger: 'blur',
        },
      ],
      country: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputCountry'),
          trigger: 'blur',
        },
      ],
      timezone: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputTimeZone'),
          trigger: 'blur',
        },
      ],
      // parentId: [{ required: true, validator: this.validateParentId, trigger: 'input'}] // parent Org should not be required if it's self
    };
    return rules;
  }

  handleSelect() {
    (this.$refs.placeHolderOrgForm as any)?.validateField('parentId');
  }

  handleTreeselectOpen() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(-90deg)');
  }

  handleTreeselectClose() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(90deg)');
  }

  get handleOrgNameValidation() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('organizationModule.duplicateName')}`;
    }
    return errInfo;
  }

  handleInput(input: string) {
    this.$emit('handle-input');
  }

  saveNewOrg() {
    this.placeHolderOrgForm.companyId = this.orgForm.companyId;
    (this.$refs.orgFormRef as any).validate((valid: any) => {
      if (valid) {
        this.$emit('save-new-org', this.placeHolderOrgForm);
      } else {
      }
    });
  }

  customKey(node: any) {
    return {
      id: node.id,
      label: node.name,
      children: node.children.length > 0 ? node.children : undefined,
    };
  }

  created() {
    getTimezones().then(({ data }) => (this.timeZoneOptions = data));
    const loggedInUser = useLoggedInUser();
    const org = unref(loggedInUser)?.organization;
    this.orgInfos = org ? [org] : [];
  }

  /**
   * Get current organization parent name for subdivision of read only field
   */
  get currentOrgParentName(): string {
    const flatOrgs: Organization[] = flattenOrganizations(this.orgInfos);
    const finalOrg = flatOrgs.find(
      (org: Organization) => org.id === this.orgId
    );
    return finalOrg?.parentName || '';
  }
}
