export const DEPLOYMENT_HISTORY_MANAGEMENT_COLS = [
  {
    label: 'deployManagementModule.deployments.filters.systemReleaseId',
    prop: 'systemReleaseId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.deploymentStartTime',
    dateFormat: 'datetime',
    prop: 'deploymentStartTime',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.deploymentEndTime',
    dateFormat: 'datetime',
    prop: 'deploymentEndTime',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.totalTimeSpent',
    prop: 'totalTimeSpent',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.deploymentBy',
    prop: 'deploymentBy',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.companyName',
    prop: 'companyName',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.noOfInvolvedAssets',
    prop: 'noOfInvolvedAssets',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.successRate',
    prop: 'successRate',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.productModel',
    prop: 'productModelCode',
    required: false,
    visible: true,
    sortable: true,
  },
  {
    label: 'deployManagementModule.deployments.filters.productModelNumber',
    prop: 'productModelNumber',
    required: false,
    visible: true,
    sortable: true,
  },
];
