export const DASHBOARDCONF_COLS = [
  {
    label: 'dashboardConf.templateName',
    prop: 'name',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'dashboardConf.custType',
    prop: 'customerType',
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'dashboardConf.industry',
    prop: 'industry',
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  // {
  //     //isDefault field was returned by API
  //     label: 'dashboardConf.type',
  //     prop: 'isDefault',
  //     visible: true,
  //     multiLang: true,
  //     sortable: 'custom'
  // },
  {
    label: 'dashboardConf.type',
    prop: 'type',
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'dashboardConf.action',
    prop: 'action',
    required: true,
    visible: true,
    sortable: false,
  },
];

export const DASHBOARDCONF_SEARCH_FIELDS = [
  {
    label: 'dashboardConf.templateName',
    prop: 'name',
  },
  // {
  //     label: 'dashboardConf.custType',
  //     prop: 'customerType'
  // },
  // // {
  // //     //isDefault field was returned by API
  // //     label: 'dashboardConf.type',
  // //     prop: 'isDefault',
  // //     visible: true,
  // //     multiLang: true,
  // //     sortable: 'custom'
  // // },
  // {
  //     label: 'dashboardConf.type',
  //     prop: 'type'
  // }
];

// Pages added to this list don't require any widget in order to be added to a dashboard template
export const PAGE_NO_WIDGET = [
  'AUTHRSC_PAGE_MAINTEN_HISTORY',
  'AUTHRSC_PAGE_MAINTEN_PLANNER',
  'AUTHRSC_PAGE_USERMNG',
  'AUTHRSC_PAGE_ORGMNG',
  'AUTHRSC_PAGE_USER_SETTINGS',
  'AUTHRSC_PAGE_SUBSCRIBED_REPORTS',
  'AUTHRSC_PAGE_REPORT_TEMPLATES',
  'AUTHRSC_PAGE_RELEASE_MANAGEMENT',
  'AUTHRSC_PAGE_DEPLOYMENTS',
  'AUTHRSC_PAGE_PENDING_UPDATES',
  'AUTHRSC_PAGE_DEPLOYMENT_HISTORY',
  'AUTHRSC_PAGE_TRIP_LIVE',
  'AUTHRSC_PAGE_TRIPS_READ',
  'AUTHRSC_PAGE_TRIP_TRACKS_READ',
  'AUTHRSC_PAGE_HEALTH_FACTOR',
  'AUTHRSC_PAGE_REGISTERED_ASSETS',
  'AUTHRSC_PAGE_CONNECTED_ASSETS',
  'AUTHRSC_PAGE_ASSIGNED_ASSETS',
  'AUTHRSC_PAGE_GEOFENCES',
  'AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION',
  'AUTHRSC_PAGE_ALARM_CONFIGURATION',
];
