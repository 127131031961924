import { RouteConfig } from 'vue-router';
import Layout from '@/components/layout/Layout.vue';

const maintenanceConfig: RouteConfig = {
  path: '/maintenance-conf',
  component: Layout,
  redirect: '/maintenance-conf/index',
  meta: {
    code: 'AUTHRSC_MOD_HOME',
  },
  children: [
    {
      path: 'index',
      component: () =>
        import(
          /* webpackChunkName: "maintenanceConf" */ '@/views/maintenanceConf/MaintenanceConf.vue'
        ),
      name: 'maintConf',
      meta: {
        title: 'route.maintConf',
        icon: 'maintConf',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_HOME',
      },
    },
    {
      path: 'create-new-maint-item',
      component: () =>
        import(
          /* webpackChunkName: "maintenanceConf" */ '@/views/maintenanceConf/AddNewMaintConf.vue'
        ),
      name: 'createNewMaintItem',
      meta: {
        title: 'route.maintConf',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_HOME',
        hidden: true,
      },
    },
    {
      path: 'create-new-maint-item/edit/:id',
      component: () =>
        import(
          /* webpackChunkName: "maintenanceConf" */ '@/views/maintenanceConf/AddNewMaintConf.vue'
        ),
      name: 'editNewMaintItem',
      props: true,
      meta: {
        title: 'route.maintConf',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_HOME',
        hidden: true,
      },
    },
    {
      path: 'view-maintenance-conf/:id',
      component: () =>
        import(
          /* webpackChunkName: "maintenanceConf" */ '@/views/maintenanceConf/ViewMaintConf.vue'
        ),
      name: 'viewMaintItem',
      props: true,
      meta: {
        title: 'route.maintConf',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_HOME',
        hidden: true,
      },
    },
  ],
};

export default maintenanceConfig;
