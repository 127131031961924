// table column dto
export interface UIColumn {
  name: string;
  code: string;
  isKpiData: boolean;
  dataFieldName: string;
  kpiUnitCode: string;
  isMandatory: boolean;
  isVisible: boolean;
  order: number;
}

// ui table dto
export interface UITable {
  code: string;
  columns: UIColumn[];
}

// interface used by table component
export interface TableColumn {
  label: string;
  code: string;
  prop: string;
  required: boolean;
  visible: boolean;
  order: number;
  kpiUnitCode: string;
  isKpiData: boolean; // TODO: See if we can automate the retrieval of this data in the future
}

export enum ColumnRenderType {
  Generic, // no special behavoir
  Date, // format as date
  DateTime, // format as date-time
  Component, // render as custom component
  Translatable, // translate contents
}

// informs UtilTable how to render a cell
export type ColumnRendering = {
  type: ColumnRenderType; // Type of rendering (see above)
  width?: number; // Column width, defaults to 180
  translatePrefix?: string; // Prefix will be added to the translation key if provided
  hideKpiUnit?: boolean; // Set to true to hide KPI value units
  sortable?: boolean; // Columns are sortable by default. Turn this flag false to change that
  overflow?: boolean; // Some columns shouldn't display the overflow popup
  sortField?: string; // If not provided, will use property name instead
  getValue?: (originalValue: any) => string; // Optional function to preprocess the value of the current cell
  getComponent?: () => any; // Required if RenderType is Component. Arrow function to import component, see workData/tableCustomization.ts
  getComponentProperties?: (row: any) => {}; // Function to map properties to custom components. If not provided, will map to default component property 'row'
};

// generic kpi data field
export type KpiData = {
  code: string;
  unit: string;
  value: any;
};
