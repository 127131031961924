import { KPI_UNIT } from '@/utils/workData/lookuptable';

type ConvertFunction = (value: number) => number;

class UnitConverterUtils {
  private conversionMap: Record<string, ConvertFunction> = {};

  constructor() {
    const linearConvert = (value: number, rate: number = 1000) => value * rate;

    this.registerConverter(
      KPI_UNIT.Kilogram,
      KPI_UNIT.MetricTonne,
      (value: number) => linearConvert(value, 1 / 1000)
    );

    this.registerConverter(
      KPI_UNIT.MetricTonne,
      KPI_UNIT.Kilogram,
      (value: number) => linearConvert(value, 1 * 1000)
    );

    this.registerConverter(
      KPI_UNIT.Meter,
      KPI_UNIT.Kilometer,
      (value: number) => linearConvert(value, 1 / 1000)
    );

    this.registerConverter(
      KPI_UNIT.MetersPerSecond,
      KPI_UNIT.KilometerPerHour,
      (value: number) => linearConvert(value, 3.6)
    );
  }

  private registerConverter(
    fromUnit: KPI_UNIT,
    toUnit: KPI_UNIT,
    convertFunction: ConvertFunction
  ) {
    this.conversionMap[this.getConverterId(fromUnit, toUnit)] = convertFunction;
  }

  private getConverterId(fromUnit: KPI_UNIT, toUnit: KPI_UNIT) {
    return `${fromUnit}_${toUnit}`;
  }

  // converts a given value from one unit of measurement to another
  public convertUnit(
    value: number,
    fromUnit: KPI_UNIT,
    toUnit: KPI_UNIT
  ): number {
    if (!fromUnit || !toUnit) return value;

    const converterId = this.getConverterId(fromUnit, toUnit);
    const converter = this.conversionMap[converterId];

    if (!converter) {
      throw `Conversion between ${fromUnit} and ${toUnit} is not implemented`;
    }

    return converter(value);
  }
}

export const unitConverterUtils = new UnitConverterUtils();
