var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TableContainer',[_c('el-tabs',{staticClass:"admin-tab",on:{"tab-click":_vm.handleTabClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.hasMenu('AUTHRSC_PAGE_USERMNG'))?_c('el-tab-pane',{attrs:{"label":_vm.$t('administrationModule.userManagement'),"name":"userTab"}},[_c('UserMgmt')],1):_vm._e(),(_vm.hasMenu('AUTHRSC_PAGE_ORGMNG'))?_c('el-tab-pane',{attrs:{"label":_vm.$t('administrationModule.organizationManagement'),"name":"orgTab"}},[_c('OrgMgmt',{ref:"orgMgmt"})],1):_vm._e(),(
        _vm.hasMenu('AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION') &&
        _vm.tabNotVisibleForBodyBuilderAndDealer
      )?_c('el-tab-pane',{attrs:{"label":_vm.$t('administrationModule.alarmSettings'),"name":"alarmTab"}},[_c('AlarmConfigurationForCustomer',{ref:"alarmConfig"})],1):_vm._e(),(
        _vm.hasMenu('AUTHRSC_PAGE_GEOFENCES') &&
        _vm.tabNotVisibleForBodyBuilderAndDealer
      )?_c('el-tab-pane',{attrs:{"label":_vm.$t('administrationModule.geofences'),"name":"geofences"}},[(_vm.activeName === 'geofences')?_c('geofence',{ref:"geofences"}):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }