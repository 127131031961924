import moment from 'moment';
import { DateRange } from '@/utils/types/date';

export function formatTime(seconds: number) {
  return format(moment.duration(seconds * 1000));
}

export function formatDuration(start: Date | string, end: Date | string) {
  return format(moment.duration(moment(start).diff(end)));
}

function format(duration: any) {
  const h = `${duration.hours()}h`;
  const m = `${duration.minutes()}m`;
  const s = `${duration.seconds()}s`;

  return `${duration.hours() ? h : ''} ${duration.minutes() ? m : ''} ${
    duration.seconds() ? s : ''
  }`;
}

/**
 * Local datetime representing start of today.
 *
 * Convenient starting point for other calculations.
 */
export function getStartOfDay() {
  return moment().startOf('day');
}

/**
 * Return the period (i.e. duration in days) of the given dateRange.
 */
export function getRangePeriod(dateRange: DateRange) {
  return moment(dateRange.endExclusive).diff(dateRange.start, 'days');
}

/**
 * Helper to convert a given daterange in the range before that.
 * For example, given a period of 7 days, returns the range of 7 days before that.
 */
export function getPreviousRange(dateRange: DateRange): DateRange {
  const periodInDays = getRangePeriod(dateRange);

  return {
    start: moment(dateRange.start)
      .subtract(periodInDays, 'days')
      .format(LOCALDATE_FORMAT),
    end: moment(dateRange.end)
      .subtract(periodInDays, 'days')
      .format(LOCALDATE_FORMAT),
    endExclusive: moment(dateRange.endExclusive)
      .subtract(periodInDays, 'days')
      .format(LOCALDATE_FORMAT),
  };
}

export const DEFAULT_NUMBER_OF_LAST_DAYS = 7;
export const NUMBER_OF_LAST_DAYS_15 = 15;

/**
 * `moment` format used to get a format accepted by backend.
 */
export const LOCALDATE_FORMAT = 'YYYY-MM-DD';

/**
 * `moment` format used in export reports.
 */
export const REPORTDATE_FORMAT = 'YYYYMMDD';

/**
 * `momentz` format used by maintenance scheduler
 */
export const MAINTENANCE_SCHEDULER_DATE_TIME_PATTERN = 'YYYY/MM/DD HH:mm';

/**
 * Used for maintenance scheduler header
 */
export const MAINTENANCE_SCHEDULER_FORMAT = 'ddd MM/DD';

export const MONTH_DAY_FORMAT = 'MM/DD';

/**
 * Used for date picker component
 */
export const DATE_PICKER_DEFAULT_FORMAT = 'yyyy-MM-dd';

/**
 * DateRange representing the default period (e.g. last 7 days).
 */
export const DEFAULT_DATE_RANGE: DateRange = {
  start: getStartOfDay()
    .subtract(DEFAULT_NUMBER_OF_LAST_DAYS, 'days')
    .format(LOCALDATE_FORMAT),
  end: getStartOfDay().subtract(1, 'day').format(LOCALDATE_FORMAT),
  endExclusive: getStartOfDay().format(LOCALDATE_FORMAT),
};

export const DEFAULT_DATE_RANGE_15_DAYS: DateRange = {
  start: getStartOfDay()
    .subtract(NUMBER_OF_LAST_DAYS_15, 'days')
    .format(LOCALDATE_FORMAT),
  end: getStartOfDay().subtract(1, 'day').format(LOCALDATE_FORMAT),
  endExclusive: getStartOfDay().format(LOCALDATE_FORMAT),
};

export function getDateRangeFromStartAndEnd(
  start: string,
  end: string
): DateRange {
  return {
    start,
    end: end,
    endExclusive: moment(end).add(1, 'day').format(LOCALDATE_FORMAT),
  };
}

export function getDateRangeFromStartAndEndExclusive(
  start: string,
  endExclusive: string
): DateRange {
  return {
    start,
    end: moment(endExclusive).subtract(1, 'day').format(LOCALDATE_FORMAT),
    endExclusive: endExclusive,
  };
}
