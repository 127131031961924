import { getAssetTypes } from '@/api/report';
const assetTypesStorageName: string = 'assetTypesList';

export const getAssetTypesList = async () => {
  let assetTypes: any = sessionStorage.getItem(assetTypesStorageName);
  if (assetTypes) {
    return JSON.parse(assetTypes);
  } else {
    return await getAssetTypes().then((response) => {
      assetTypes = response.data;
      sessionStorage.setItem(assetTypesStorageName, JSON.stringify(assetTypes));

      return assetTypes;
    });
  }
};

export interface ReportAssetType {
  assetType: string;
  kpis: ReportAssetTypeKpi[];
}

export interface ReportAssetTypeKpi {
  code: string;
  statisticsMethods: string[];
  unit: string;
}
