import Layout from '@/components/layout/Layout.vue';
import { ContextType } from '@/composables/context';
import { AssetType } from '@/utils/workData/lookuptable';
import { RouteConfig } from 'vue-router';

const assetsRoutes: RouteConfig = {
  path: '/assets',
  component: Layout,
  redirect: '/assets/table-top-tissectors',
  name: 'Assets',
  meta: {
    title: 'route.assets',
    icon: 'assets',
    alwaysShow: true,
    code: [
      'AUTHRSC_MOD_ASSETS',
      'AUTHRSC_MOD_MCOMPACTOR',
      'AUTHRSC_MOD_SCOMPACTOR',
      'AUTHRSC_MOD_TIPPING_VEHICLES',
      'AUTHRSC_MOD_TABLE_TOP_TISSECTOR',
    ],
    context: ContextType.SelectedCustomer,
  },
  children: [
    {
      path: 'table-top-tissectors',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: 'TableTopTissectors',
      meta: {
        title: 'route.tableTopTissectors',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_TABLE_TOP_TISSECTOR',
        systemFeature: [null, AssetType.TableTopTissector],
      },
    },
    {
      path: 'table-top-tissectors/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: 'TableTopTissectorInfo',
      props: true,
      meta: {
        title: 'route.tableTopTissectors',
        thirdLevelMenu: true,
        roles: ['admin'],
        code: 'AUTHRSC_MOD_TABLE_TOP_TISSECTOR',
        hidden: true,
        systemFeature: [null, AssetType.TableTopTissector],
      },
    },
    {
      path: 'mobile-compactors',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: 'MobileCompactors',
      meta: {
        title: 'route.mobileCompactors',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_MCOMPACTOR',
        systemFeature: [null, AssetType.MobileCompactor],
      },
    },
    {
      path: 'mobile-compactors/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: 'MobileCompactorInfo',
      props: true,
      meta: {
        title: 'route.mobileCompactors',
        thirdLevelMenu: true,
        roles: ['admin'],
        code: 'AUTHRSC_MOD_MCOMPACTOR',
        hidden: true,
        systemFeature: [null, AssetType.MobileCompactor],
      },
    },
    {
      path: 'static-compactors',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: 'StaticCompactors',
      meta: {
        title: 'route.staticCompactors',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_SCOMPACTOR',
        systemFeature: [null, AssetType.StaticCompactor],
      },
    },
    {
      path: 'static-compactors/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: 'StaticCompactorInfo',
      props: true,
      meta: {
        title: 'route.staticCompactors',
        thirdLevelMenu: true,
        roles: ['admin'],
        code: 'AUTHRSC_MOD_SCOMPACTOR',
        hidden: true,
        systemFeature: [null, AssetType.StaticCompactor],
      },
    },
    {
      path: 'tipping-vehicles',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: 'TippingVehicles',
      meta: {
        title: 'route.tipping',
        roles: ['admin'],
        code: 'AUTHRSC_MOD_TIPPING_VEHICLES',
        systemFeature: [null, AssetType.TippingVehicle],
      },
    },
    {
      path: 'tipping-vehicles/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: 'TippingVehiclesInfo',
      props: true,
      meta: {
        title: 'route.tipping',
        thirdLevelMenu: true,
        roles: ['admin'],
        code: 'AUTHRSC_MOD_TIPPING_VEHICLES',
        hidden: true,
        systemFeature: [null, AssetType.TippingVehicle],
      },
    },
  ],
};

export default assetsRoutes;
