
import { getEvents } from '@/api/event';
import {
  deleteGeofence,
  getAllGeofencesByOrganisationId,
} from '@/api/geofence';
import TimeSelect from '@/components/form/TimeSelect.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  LoggedInUserRef,
  useMutableLoggedInUser,
  ActiveContext,
  useActiveContext,
  flattenOrganizations,
} from '@/composables/context';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { Geofence } from '../../../api/geofenceTypes';
import GeofenceCard from './components/GeofenceCard.vue';
import CreateNewGeofence from './CreateNewGeofence.vue';
import EditGeofence from './EditGeofence.vue';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { promptSuccessBox } from '@/utils/prompt';

interface SearchParam {
  reference: string | null;
  value: string | null;
}

@Component({
  name: 'Geofence',
  components: {
    TimeSelect,
    PureTable,
    LeafletMap,
    SelectTableHeader,
    CreateNewGeofence,
    EditGeofence,
    GeofenceCard,
  },
})
export default class extends Vue {
  /** Local variables */
  geofences: Geofence[] = [];
  displayList: Geofence[] = [];
  id: string = '';
  startDate: string = '';
  endDate: string = '';
  trackingHistoryIsLoading: boolean = false;
  cols = [
    {
      label: 'assets.assetId',
      prop: 'companyAssetId',
      required: true,
      visible: true,
    },
    {
      label: 'assets.eventName',
      prop: 'eventName',
      required: true,
      visible: true,
    },
    {
      label: 'assets.timeStamp',
      prop: 'timestamp',
      dateFormat: 'datetime',
      required: true,
      visible: true,
    },
    {
      label: 'assets.description',
      prop: 'eventDescription',
      required: true,
      visible: true,
    },
  ];
  active = 'geofences';
  center: number[] = [46.771211, 23.623634];
  zoom: number = 10;
  geofenceConfig = {
    enableGeofence: true,
    enableCreate: false,
    editOnly: false,
    showLifeCycleStatus: true,
  };
  selectedGeofenceId: string = '';
  selectedGeofenceIndex: number | null = null;
  geofenceHistory: unknown[] = [];
  loggedInUser!: LoggedInUserRef;
  context!: Ref<ActiveContext>;
  geofenceListPageIsLoading: boolean = false;
  searchFieldOptions = [
    {
      label: 'geofence.geofenceName',
      prop: 'name',
      visible: true,
      sortable: 'custom',
      multiLang: true,
    },
    {
      label: 'geofence.geofenceType',
      prop: 'geofenceType',
      visible: true,
      multiLang: true,
    },
    {
      label: 'geofence.geofenceStatus',
      prop: 'lifeCycle',
      visible: true,
      multiLang: true,
    },
    {
      label: 'geofence.organization',
      prop: 'organizationName',
      visible: true,
      multiLang: true,
    },
  ];
  searchParams: SearchParam = {
    reference: null,
    value: null,
  };

  $refs!: {
    map: LeafletMap;
  };

  get selectedSubpage(): string {
    return !this.selectedGeofenceId ? 'geofences' : this.active;
  }

  created() {
    this.loggedInUser = useMutableLoggedInUser().ref;
    this.context = useActiveContext();
  }

  async mounted() {
    await this.loadGeofences();
    this.$refs.map.initializeGeofence(this.geofences);
    if (this.$route.query.geofence) {
      const geofence = this.geofences.find((geofence) => {
        if (geofence.id === this.$route.query.geofence) return geofence;
      });
      if (geofence) {
        this.zoomToGeofence(geofence);
      }
    } else {
      this.$refs.map.setBoundsToGeofences();
    }
  }

  /**
   * Zoom to geofence
   * @param geofence
   */
  zoomToGeofence(geofence: Geofence): void {
    if (geofence.id === this.selectedGeofenceId) {
      this.selectedGeofenceId = '';

      const query = Object.assign({}, this.$route.query);

      delete query.geofence;

      this.$router.replace({ query });
      this.$refs.map.renderGeofences(this.geofences);

      return;
    }

    this.selectedGeofenceId = geofence.id;
    if (!this.$route.query.geofence) {
      this.$router.replace({
        path: this.$route.fullPath,
        query: { geofence: geofence.id },
      });
    }
    this.$refs.map.renderSingleGeofence(geofence);
    this.$refs.map.zoomToGeofence(geofence);
    this.loadGeofenceHistory(
      this.selectedGeofenceId,
      this.startDate,
      this.endDate
    );
  }

  /**
   * Edit current geofence by id
   * @param id
   */
  edit(id: string): void {
    this.$router.push(`edit-geofence/${id}`);
  }

  /**
   * Navigate to sub page by map or tracking history (events)
   * @param label
   */
  async navigateToSubpage(label: string): Promise<void> {
    this.active = label;
    await this.loadGeofences();
  }

  /**
   * Load geofence by id
   */
  private async loadGeofences(): Promise<void> {
    try {
      this.geofenceListPageIsLoading = true;
      const organization = unref(this.context).organization;
      if (!organization) {
        return;
      }

      const organizations = flattenOrganizations(organization);
      this.geofences = [];

      for (const organization of organizations) {
        const res = await getAllGeofencesByOrganisationId(organization.id);
        const geofences = res.data.geofences.map((geofence) => ({
          ...geofence,
          organizationName: organization.name,
        }));
        this.geofences = this.geofences.concat(geofences);
      }
      this.searchGeofences();
    } catch (error) {
    } finally {
      this.geofenceListPageIsLoading = false;
    }
  }

  /**
   * Load geofence history (events)
   * @param id
   * @param start
   * @param end
   */
  private async loadGeofenceHistory(
    id: string,
    start: string,
    end: string
  ): Promise<void> {
    if (!start || !end || (!start && !end && this.active == 'label')) return;
    try {
      this.trackingHistoryIsLoading = true;
      const res = await getEvents({
        filters: [
          {
            name: 'timestamp',
            operator: FilterOperator.BETWEEN,
            value: [start, end],
          },
          {
            name: 'PARM.GeoFenceId',
            operator: FilterOperator.EQUAL,
            value: [id],
          },
        ],
        pagination: {
          page: 1,
          size: 1000,
        },
      });
      if (res.code === 200) {
        this.geofenceHistory = res.data.events.filter(
          (ev) =>
            ev.eventTypeCode === 'EVNT_GEOFENCE_ENTRY' ||
            ev.eventTypeCode === 'EVNT_GEOFENCE_EXIT'
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.trackingHistoryIsLoading = false;
    }
  }

  /**
   * Search geofences
   */
  async searchGeofences(): Promise<void> {
    if (!this.searchParams.value) {
      this.displayList = this.geofences;
    } else {
      const searchParam = this.searchParams
        .reference as keyof typeof this.geofences[0];

      switch (this.searchParams.reference) {
        case 'name':
          this.displayList = this.geofences.filter(
            (geofence) =>
              geofence[searchParam] &&
              `${geofence[searchParam]}`
                .toLowerCase()
                .includes(this.searchParams.value!.toLowerCase())
          );
          break;
        case 'geofenceType':
          this.displayList = this.geofences.filter(
            (geofence) =>
              geofence[searchParam] &&
              `${this.$t(`${geofence[searchParam]}`)}`
                .toLowerCase()
                .includes(this.searchParams.value!.toLowerCase())
          );
          break;
        case 'lifeCycle':
          this.displayList = this.geofences.filter(
            (geofence) =>
              geofence[searchParam] &&
              `${this.$t(`${geofence[searchParam]}`)}`
                .toLowerCase()
                .includes(this.searchParams.value!.toLowerCase())
          );
          break;
        case 'organizationName':
          this.displayList = this.geofences.filter(
            (geofence) =>
              geofence[searchParam] &&
              `${geofence[searchParam]}`
                .toLowerCase()
                .includes(this.searchParams.value!.toLowerCase())
          );
          break;
      }
    }
  }

  /**
   * Delete geofence by id
   * @param geofenceId
   */
  async deleteGeofence(geofenceId: string): Promise<void> {
    try {
      this.geofenceListPageIsLoading = true;
      const response = await deleteGeofence(geofenceId);
      this.geofenceListPageIsLoading = false;
      if (response.code === 200) {
        promptSuccessBox(this.$t('common.deleted').toString());
        this.geofences = this.geofences.filter(
          (element) => element.id != geofenceId
        );
        this.displayList = this.geofences;
      }
      this.$refs.map.renderGeofences(this.geofences);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Filter tracking history
   * @param { start: string; end: string }
   */
  filterHistory({ start, end }: { start: string; end: string }): void {
    this.startDate = start;
    this.endDate = end;
    this.loadGeofenceHistory(this.selectedGeofenceId, start, end);
  }
}
