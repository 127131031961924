import { getAllServiceRegions } from '@/api/serviceRegions';
const allServiceRegionsStorageName: string = 'allServiceRegions';

/**
 * Get service regions
 * - first check in session storage
 * - if there are no session storage service regions retrive from remote api and set session storage with data
 * @returns ServiceRegion[]
 */
export async function getAllServiceRegionsList(): Promise<ServiceRegion[]> {
  let serviceRegions: ServiceRegion[] = [];
  try {
    let allServiceRegions: string | undefined | null = sessionStorage.getItem(
      allServiceRegionsStorageName
    )!;
    if (allServiceRegions) {
      serviceRegions = JSON.parse(allServiceRegions);
    } else {
      serviceRegions = await getAllServiceRegionsFromApi();
      sessionStorage.setItem(
        allServiceRegionsStorageName,
        JSON.stringify(serviceRegions)
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    return serviceRegions;
  }
}

async function getAllServiceRegionsFromApi() {
  const response = await getAllServiceRegions({});
  return response.data;
}

export interface ServiceRegion {
  id?: string;
  serviceRegionCode: string;
  serviceRegionName: string;
  deploymentRegionCode: string;
}
