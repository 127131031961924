import { GeofencePosition, TrackingDays } from '@/api/geofenceTypes';
import VueI18n from 'vue-i18n';

export interface GeofenceError {
  code: string;
  field: string;
  message: string | VueI18n.TranslateResult;
}

export const checkFieldErrors = (
  errorInfos: GeofenceError[],
  geofenceForm: {
    name: string;
    organizationId?: string;
    geofenceType: string;
    associatedAssetTypes: string[];
    tracking: string;
    tracked: boolean;
    trackingStartTime: string;
    trackingEndTime: string;
    trackingDays: TrackingDays[];
    trackingInterval: string;
    trackingMode: string;
    geofencePosition: GeofencePosition[];
  }
) => {
  let errorShouldBeThrown = false;
  if (!geofenceForm.name) {
    errorInfos.push({
      code: 'ApiErrorFieldInvalid',
      field: 'ApiFieldName',
      message: '',
    });
    errorShouldBeThrown = true;
  }
  if (!geofenceForm.organizationId) {
    errorInfos.push({
      code: 'ApiErrorFieldRequired',
      field: 'ApiFieldOrganizationId',
      message: '',
    });
    errorShouldBeThrown = true;
  }
  if (!geofenceForm.geofenceType) {
    errorInfos.push({
      code: 'ApiErrorFieldInvalid',
      field: 'ApiFieldGeofenceType',
      message: '',
    });
    errorShouldBeThrown = true;
  }
  if (geofenceForm.associatedAssetTypes.length === 0) {
    errorInfos.push({
      code: 'ApiErrorFieldRequired',
      field: 'ApiFieldAssociatedAssetTypes',
      message: '',
    });
    errorShouldBeThrown = true;
  }
  if (geofenceForm.geofencePosition.length < 3) {
    errorInfos.push({
      code: 'ApiErrorFieldRequired',
      field: 'ApiFieldGeofencePosition',
      message: '',
    });
    errorShouldBeThrown = true;
  }
  if (
    geofenceForm.tracked &&
    geofenceForm.trackingMode === 'GFNTRACK_TIME_PERIOD'
  ) {
    if (!geofenceForm.trackingInterval) {
      errorInfos.push({
        code: 'ApiErrorFieldNotFound',
        field: 'ApiFieldTracking',
        message: '',
      });
      errorShouldBeThrown = true;
    }
    if (
      geofenceForm.trackingInterval === 'GFNTRACK_INSIDE_TIME_INTERVAL' ||
      geofenceForm.trackingInterval === 'GFNTRACK_OUTSIDE_TIME_INTERVAL'
    ) {
      if (!geofenceForm.trackingStartTime) {
        errorInfos.push({
          code: 'ApiErrorFieldInvalid',
          field: 'ApiFieldStartDate',
          message: '',
        });
        errorShouldBeThrown = true;
      }

      if (!geofenceForm.trackingEndTime) {
        errorInfos.push({
          code: 'ApiErrorFieldInvalid',
          field: 'ApiFieldEndDate',
          message: '',
        });
        errorShouldBeThrown = true;
      }

      if (geofenceForm.trackingDays.length === 0) {
        errorInfos.push({
          code: 'ApiErrorFieldNotFound',
          field: 'ApiFieldTrackingDays',
          message: '',
        });
        errorShouldBeThrown = true;
      }

      if (
        !!geofenceForm.trackingStartTime &&
        !!geofenceForm.trackingEndTime &&
        geofenceForm.trackingStartTime > geofenceForm.trackingEndTime
      ) {
        errorInfos.push({
          code: 'ApiErrorFieldInvalid',
          field: 'ApiFieldTrackingEndTime',
          message: '',
        });
        errorShouldBeThrown = true;
      }
    }
  }

  if (errorShouldBeThrown) {
    throw '';
  }
};
