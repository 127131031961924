
import { Component, Prop, Vue } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import UserInfo from './components/UserInfo.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import {
  getUserById,
  getModules,
  updateActivationStatus,
  deleteUser,
} from '@/api/users';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import {
  promptSuccessBox,
  promptFailedBox,
  customFailedMessage,
} from '@/utils/prompt';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import { getOrgById } from '@/api/organizations';

@Component({
  name: 'viewCustInfo',
  components: {
    'new-card': NewCard,
    'user-info': UserInfo,
    'module-access': ModuleAccess,
    'deactivate-dialog': DeactivateDialog,
  },
})
export default class extends Vue {
  @Prop({ required: true }) userId!: string;

  /** Local variables */
  editBtnAuth: string[] = ['AUTHRSC_MOD_USER_EDIT'];
  deacBtnAuth: string[] = ['AUTHRSC_MOD_USER_DEACT'];
  removeBtnAuth: string[] = ['AUTHRSC_MOD_USER_REMOVE'];

  userInfo: any = {};
  activateOrDeactivate: string = '';
  modulesInfo: any = [];
  defaultChecked: string[] = [];
  title: string = '';
  activeName: string = 'first';
  visiable: boolean = false;
  dialogContent: any = '';
  orgNum: number = 0;
  userNum: number = 0;
  userTotal: number = 15;
  isUserInfoLoading: boolean = false;
  loadingText: string = this.$t('userModule.loadingUserData') as string;
  deleteDialogVisible: boolean = false;
  notAllowedActionsByHimSelf: string[] = ['REMOVE', 'DEACTIVATE'];
  activateOrDeactivateBtnIsVisible: boolean = false;

  created() {
    this.fetchUserModules();
    this.userId && this.fetchUserData();
  }

  async fetchUserModules() {
    await getModules().then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code) {
        this.modulesInfo = res.data;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  /**
   * Fetch user data remotly from API
   */
  async fetchUserData(): Promise<void> {
    try {
      this.isUserInfoLoading = true;
      const res = await getUserById(this.userId);

      if (res.code === 200) {
        this.userInfo = res.data;
        //this.userInfo = Object.assign({}, this.userInfo, res.data);
        this.title = this.userInfo.username;
        PageModule.setTitle(this.title);
        if (this.userInfo.activationStatus === ACTIVATION_STATUS.Deactivated) {
          this.activateOrDeactivate = 'activate';
        } else {
          this.activateOrDeactivate = 'deactivate';
        }

        let arr: string[] = [];
        this.userInfo.claims.forEach((item: any) => {
          arr.push(item.resource);
        });

        this.defaultChecked = arr;
        this.getOrganizationById(res.data.organizationId);
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
    }
  }

  /**
   * Handle cancel modal event
   */
  handleCancel(): void {
    this.visiable = false;
  }

  /**
   * Show deactivate modal
   */
  showDeactive(): void {
    if (this.selfCheckNotAllowedAction('DEACTIVATE')) {
      return;
    }
    this.visiable = true;
    if (this.userInfo.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.dialogContent = this.$t('userModule.activateInfo', {
        name: this.title,
      });
    } else {
      this.dialogContent = this.$t('userModule.deactivateInfo', {
        name: this.title,
      });
    }
  }

  /**
   * Handle user deactivation
   */
  async handleDeactive(): Promise<void> {
    let infoMsg = (
      this.activateOrDeactivate === 'deactivate'
        ? this.$t('common.deactivate')
        : this.$t('common.activate')
    ) as string;

    await updateActivationStatus(this.userId, {
      activationStatus:
        this.activateOrDeactivate === 'deactivate'
          ? ACTIVATION_STATUS.Deactivated
          : ACTIVATION_STATUS.Activated,
    }).then((res) => {
      if (res.code === 200) {
        promptSuccessBox(infoMsg);

        this.userInfo.activationStatus = ACTIVATION_STATUS.Deactivated;
        this.$router.push('/administration/index');
      } else if (res.code === 400) {
        promptFailedBox(infoMsg);
      }
    });

    this.visiable = false;
  }

  /**
   * Handle delete user
   */
  async handleUserDelete() {
    var response = await deleteUser(this.userId);
    this.deleteDialogVisible = false;

    if (response.code !== 200) {
      promptFailedBox(this.$t('common.remove') as string);
      return;
    }

    promptSuccessBox(this.$t('common.remove') as string);
    this.$router.push('/administration/index');
  }

  /**
   * Close modal event
   */
  closeDeleteDialog(): void {
    this.deleteDialogVisible = false;
  }

  /**
   * Show delete dialog
   */
  showDeleteDialog(): void {
    if (this.selfCheckNotAllowedAction('REMOVE')) {
      return;
    }
    this.deleteDialogVisible = true;
  }

  /**
   * Handle click
   * @param tab
   * @param event
   */
  handleClick(tab: any, event: any): void {}

  /**
   * Handle edit event
   */
  handleEdit() {
    this.$router.push(`/administration/edit-user/${this.userId}`);
  }

  /**
   * Self check not allowed actions like removed or deactivated/active logged in user himself
   */
  selfCheckNotAllowedAction(action: string): boolean {
    if (
      this.userInfo.email === UserModule.email &&
      this.notAllowedActionsByHimSelf.includes(action)
    ) {
      const errorMessage: string =
        action === 'REMOVE'
          ? (this.$t(
              'administrationModule.notAllowedToRemoveYourOwnAcoount'
            ) as string)
          : action === 'DEACTIVATE' &&
            UserModule.activationStatus === ACTIVATION_STATUS.Activated
          ? (this.$t(
              'administrationModule.notAllowedToDeactivateYourOwnAcoount'
            ) as string)
          : action === 'DEACTIVATE' &&
            UserModule.activationStatus === ACTIVATION_STATUS.Deactivated
          ? (this.$t(
              'administrationModule.notAllowedToActivateYourOwnAcoount'
            ) as string)
          : '';
      customFailedMessage(errorMessage);
      return true;
    }
    return false;
  }

  /**
   * Do not allow self action on own entity like user to disable or remove
   * @return boolean
   */
  get checkIfAllowedSelfAction(): boolean {
    return this.userInfo.email != UserModule.email;
  }

  /**
   * Fetch selected user's organization to check the activation status
   * logic is used for activate/deactivate btn on the selected user to be shown only when user's org is activated
   * the loading event should be ended by this thread when all infos/constrains are available to display
   * @param organizationId
   */
  async getOrganizationById(organizationId: string): Promise<void> {
    try {
      if (!organizationId) return;
      this.activateOrDeactivateBtnIsVisible =
        (await getOrgById(organizationId)).data.activationStatus ===
        ACTIVATION_STATUS.Activated
          ? true
          : false;
    } catch (error) {
      console.log(error);
    } finally {
      this.isUserInfoLoading = false;
    }
  }
}
