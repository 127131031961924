import Vue from 'vue';
import { AssetType, SYSTEM_FEATURES } from './lookuptable';

import AssetStatus from '@/widgets/home/AssetStatus.vue';
import DashboardMap from '@/widgets/home/Map.vue';
import Events from '@/widgets/home/Events.vue';

import FleetStatus from '@/widgets/fleet/FleetStatus.vue';
import FleetWorkingHours from '@/widgets/fleet/FleetWorkingHours.vue';
import FleetPayload from '@/widgets/fleet/FleetPayload.vue';
import FleetPower from '@/widgets/fleet/FleetPower.vue';
import FleetProductivity from '@/widgets/fleet/FleetProductivity.vue';
import FleetEfficiency from '@/widgets/fleet/FleetEfficiency.vue';
import FleetSafety from '@/widgets/fleet/FleetSafety.vue';
import FleetPerformance from '@/widgets/fleet/FleetPerformance.vue';
import FleetTarget from '@/widgets/fleet/FleetTarget.vue';

import AssetRealTime from '@/widgets/asset/kpis/AssetRealTime.vue';
import AssetWorkingHours from '@/widgets/asset/kpis/AssetWorkingHours.vue';
import AssetPayload from '@/widgets/asset/kpis/AssetPayload.vue';
import AssetPower from '@/widgets/asset/kpis/AssetPower.vue';
import AssetProductivity from '@/widgets/asset/kpis/AssetProductivity.vue';
import AssetEfficiency from '@/widgets/asset/kpis/AssetEfficiency.vue';
import AssetTripLog from '@/widgets/asset/kpis/AssetTripLog.vue';
import AssetOverview from '@/widgets/asset/kpis/AssetOverview.vue';

import AssetBasicInfo from '@/widgets/asset/info/AssetBasicInfo.vue';

import MaintenanceStatus from '@/widgets/maintenance/MaintenanceStatus.vue';
import MaintenancePerformed from '@/widgets/maintenance/MaintenancePerformed.vue';
import AssetMaintenanceHistory from '@/widgets/asset/maintenance/AssetMaintenanceHistory.vue';
import AssetMaintenanceStatusList from '@/widgets/asset/maintenance/AssetMaintenanceStatusList.vue';
import MaintenancePlanList from '@/widgets/maintenance/MaintenancePlanList.vue';

import AssetModelSpec from '@/widgets/asset/specifications/AssetModelSpec.vue';
import AssetPartsInfo from '@/widgets/asset/specifications/AssetPartsInfo.vue';
import ParameterConfiguration from '@/widgets/asset/parameters/ParameterConfiguration.vue';

export interface WidgetCodeItem {
  '*': typeof Vue;
  [page: `AUTHRSC_PAGE_${string}`]: typeof Vue;
}

// TODO: merge fleet & kpi widgets (e.g. fleet work hours & asset work hours, code is like 90% the same)
// TODO: merge small & expanded widgets into same component (if it makes sense & is doable)
export const WIDGET_CODE_MAP = new Map<string, WidgetCodeItem | typeof Vue>([
  // home
  ['AUTHRSC_WIDGET_ASSETSSTATUS', AssetStatus],
  ['AUTHRSC_WIDGET_MAP', DashboardMap],
  ['AUTHRSC_WIDGET_EVENTS', Events],

  ['AUTHRSC_WIDGET_MCOMPACTOR_PARAM_CONFIG', ParameterConfiguration],
  ['AUTHRSC_WIDGET_SCOMPACTOR_PARAM_CONFIG', ParameterConfiguration],
  ['AUTHRSC_WIDGET_TIPPING_VEHICLES_PARAM_CONFIG', ParameterConfiguration],

  // fleet
  ['AUTHRSC_WIDGET_ACTSTATUS', FleetStatus],
  ['AUTHRSC_WIDGET_FLEET_WORKING_HOURS', FleetWorkingHours],
  ['AUTHRSC_WIDGET_FLEET_PAYLOAD', FleetPayload],
  ['AUTHRSC_WIDGET_FLEET_POWER_CONSUMPTION', FleetPower],
  ['AUTHRSC_WIDGET_FLEET_PRODUCTIVITY', FleetProductivity],
  ['AUTHRSC_WIDGET_FLEET_EFFICIENCY', FleetEfficiency],
  ['AUTHRSC_WIDGET_TIPPING_FLEET_SAFETY', FleetSafety],
  ['AUTHRSC_WIDGET_TIPPING_PERFORMANCE', FleetPerformance],
  ['AUTHRSC_WIDGET_TIPPING_FLEET_TARGET', FleetTarget],

  // kpis
  ['AUTHRSC_WIDGET_REALTIME', AssetRealTime],
  ['AUTHRSC_WIDGET_WORKING_HOURS', AssetWorkingHours],
  ['AUTHRSC_WIDGET_PAYLOAD', AssetPayload],
  ['AUTHRSC_WIDGET_POWER_CONSUMPTION', AssetPower],
  ['AUTHRSC_WIDGET_PRODUCTIVITY', AssetProductivity],
  ['AUTHRSC_WIDGET_EFFICIENCY', AssetEfficiency],
  ['AUTHRSC_WIDGET_TRIP_LOG', AssetTripLog],
  ['AUTHRSC_WIDGET_TIPPING_ASSET_OVERVIEW', AssetOverview],
  ['AUTHRSC_WIDGET_TIPPING_SAFETY', FleetSafety],
  ['AUTHRSC_WIDGET_TIPPING_TARGET', FleetTarget],

  // asset info
  ['AUTHRSC_WIDGET_BASICINFO', AssetBasicInfo],
  ['AUTHRSC_WIDGET_OPERATION_LOG', Events],

  // maintenance
  ['AUTHRSC_WIDGET_MAINTEN_STATUS', MaintenanceStatus],
  ['AUTHRSC_WIDGET_MAINTEN_PERFORMED', MaintenancePerformed],
  ['AUTHRSC_WIDGET_MAINTEN_HISTORY', AssetMaintenanceHistory],
  [
    'AUTHRSC_WIDGET_MAINTEN_PLAN_LIST',
    {
      '*': AssetMaintenanceStatusList,
      AUTHRSC_PAGE_MAINTEN_OVERVIEW: MaintenancePlanList,
    },
  ],

  // specifications
  ['AUTHRSC_WIDGET_MODEL_SPEC', AssetModelSpec],
  ['AUTHRSC_WIDGET_PARTSINFO', AssetPartsInfo],
]);

export const FULLPAGE_EXPANDED_WIDGETS = [
  'AUTHRSC_WIDGET_TIPPING_TARGET',
  'AUTHRSC_WIDGET_TIPPING_FLEET_TARGET',
];

export interface PageLayoutItem {
  column: number;
  w: string;
  h: string;
}

export const PAGE_LAYOUT_MAP = new Map<string, PageLayoutItem>([
  ['AUTHRSC_PAGE_HOME', { column: 4, w: '24.56%', h: '180px' }],

  ['AUTHRSC_PAGE_MCOMPACTOR_HOME', { column: 3, w: '33%', h: '243px' }],
  ['AUTHRSC_PAGE_MCOMPACTOR_KPIS', { column: 3, w: '33%', h: '248px' }],
  ['AUTHRSC_PAGE_MCOMPACTOR_ASSETINFO', { column: 3, w: '33%', h: '210px' }],
  ['AUTHRSC_PAGE_MCOMPACTOR_MAINTEN', { column: 3, w: '33%', h: '180px' }],
  [
    'AUTHRSC_PAGE_MCOMPACTOR_PARAMS_CONFIG',
    { column: 3, w: '33%', h: '180px' },
  ],
  ['AUTHRSC_PAGE_MCOMPACTOR_SPEC', { column: 3, w: '33%', h: '180px' }],

  ['AUTHRSC_PAGE_SCOMPACTOR_HOME', { column: 3, w: '33%', h: '243px' }],
  ['AUTHRSC_PAGE_SCOMPACTOR_KPIS', { column: 3, w: '33%', h: '248px' }],
  ['AUTHRSC_PAGE_SCOMPACTOR_ASSETINFO', { column: 3, w: '33%', h: '210px' }],
  ['AUTHRSC_PAGE_SCOMPACTOR_MAINTEN', { column: 3, w: '33%', h: '180px' }],
  [
    'AUTHRSC_PAGE_SCOMPACTOR_PARAMS_CONFIG',
    { column: 3, w: '33%', h: '180px' },
  ],
  ['AUTHRSC_PAGE_SCOMPACTOR_SPEC', { column: 3, w: '33%', h: '180px' }],

  ['AUTHRSC_PAGE_ALBA_SCOMPACTOR_HOME', { column: 3, w: '33%', h: '243px' }],
  ['AUTHRSC_PAGE_ALBA_SCOMPACTOR_KPIS', { column: 3, w: '33%', h: '248px' }],
  [
    'AUTHRSC_PAGE_ALBA_SCOMPACTOR_ASSETINFO',
    { column: 3, w: '33%', h: '210px' },
  ],
  ['AUTHRSC_PAGE_ALBA_SCOMPACTOR_MAINTEN', { column: 3, w: '33%', h: '180px' }],
  ['AUTHRSC_PAGE_ALBA_SCOMPACTOR_SPEC', { column: 3, w: '33%', h: '180px' }],

  ['AUTHRSC_PAGE_TIPPING_VEHICLES_HOME', { column: 3, w: '33%', h: '243px' }],
  ['AUTHRSC_PAGE_TIPPING_VEHICLES_KPIS', { column: 3, w: '33%', h: '248px' }],
  [
    'AUTHRSC_PAGE_TIPPING_VEHICLES_ASSETINFO',
    { column: 3, w: '33%', h: '210px' },
  ],
  ['AUTHRSC_PAGE_TIPPING_VEHICLES_MAINT', { column: 3, w: '33%', h: '180px' }],
  ['AUTHRSC_PAGE_TIPPING_VEHICLES_SPEC', { column: 3, w: '33%', h: '180px' }],
  [
    'AUTHRSC_PAGE_TIPPING_VEHICLES_PARAMS_CONFIG',
    { column: 3, w: '33%', h: '180px' },
  ],

  [
    'AUTHRSC_PAGE_TABLE_TOP_TISSECTOR_HOME',
    { column: 3, w: '33%', h: '243px' },
  ],
  [
    'AUTHRSC_PAGE_TABLE_TOP_TISSECTOR_KPIS',
    { column: 3, w: '33%', h: '248px' },
  ],
  [
    'AUTHRSC_PAGE_TABLE_TOP_TISSECTOR_ASSETINFO',
    { column: 3, w: '33%', h: '210px' },
  ],
  [
    'AUTHRSC_PAGE_TABLE_TOP_TISSECTOR_SPEC',
    { column: 3, w: '33%', h: '180px' },
  ],

  ['AUTHRSC_PAGE_MAINTEN_OVERVIEW', { column: 3, w: '33%', h: '180px' }],
]);

export interface RouteAssetItem {
  route: string;
  routeName: string;
  pageCode: string;
  widgetContainerId: string;
  title: string;
  assetTypeCode: AssetType;
}

export const ROUTE_ASSET_MAP = new Map<string, RouteAssetItem>([
  [
    'MobileCompactors',
    {
      route: 'mobile-compactors',
      routeName: 'MobileCompactor',
      pageCode: 'AUTHRSC_PAGE_MCOMPACTOR_HOME',
      widgetContainerId: 'asset-mc-home-container',
      title: 'route.mobileCompactors',
      assetTypeCode: AssetType.MobileCompactor,
    },
  ],
  [
    'MobileCompactorInfo',
    {
      route: 'mobile-compactors',
      routeName: 'MobileCompactor',
      pageCode: 'AUTHRSC_MOD_MCOMPACTOR',
      widgetContainerId: '',
      title: 'assets.mobileCompactors',
      assetTypeCode: AssetType.MobileCompactor,
    },
  ],
  [
    'StaticCompactors',
    {
      route: 'static-compactors',
      routeName: 'StaticCompactor',
      pageCode: 'AUTHRSC_PAGE_SCOMPACTOR_HOME',
      widgetContainerId: 'asset-sc-home-container',
      title: 'route.staticCompactors',
      assetTypeCode: AssetType.StaticCompactor,
    },
  ],
  [
    'StaticCompactorInfo',
    {
      route: 'static-compactors',
      routeName: 'StaticCompactor',
      pageCode: 'AUTHRSC_MOD_SCOMPACTOR',
      widgetContainerId: '',
      title: 'assets.staticCompactors',
      assetTypeCode: AssetType.StaticCompactor,
    },
  ],
  [
    'AlbaStaticCompactors',
    {
      route: 'alba-static-compactors',
      routeName: 'AlbaStaticCompactor',
      pageCode: 'AUTHRSC_PAGE_ALBA_SCOMPACTOR_HOME',
      widgetContainerId: 'asset-alba-sc-home-container',
      title: 'route.albaStaticCompactors',
      assetTypeCode: AssetType.AlbaStaticCompactor,
    },
  ],
  [
    'AlbaStaticCompactorInfo',
    {
      route: 'alba-static-compactors',
      routeName: 'AlbaStaticCompactor',
      pageCode: 'AUTHRSC_MOD_ALBA_SCOMPACTOR',
      widgetContainerId: '',
      title: 'assets.albaStaticCompactors',
      assetTypeCode: AssetType.AlbaStaticCompactor,
    },
  ],
  [
    'TableTopTissectors',
    {
      route: 'table-top-tissectors',
      routeName: 'TableTopTissector',
      pageCode: 'AUTHRSC_PAGE_TABLE_TOP_TISSECTOR_HOME',
      widgetContainerId: 'asset-ttt-home-container',
      title: 'route.tableTopTissectors',
      assetTypeCode: AssetType.TableTopTissector,
    },
  ],
  [
    'TableTopTissectorInfo',
    {
      route: 'table-top-tissectors',
      routeName: 'TableTopTissector',
      pageCode: 'AUTHRSC_MOD_TABLE_TOP_TISSECTOR',
      widgetContainerId: '',
      title: 'assets.tableTopTissectors',
      assetTypeCode: AssetType.TableTopTissector,
    },
  ],
  [
    'TippingVehicles',
    {
      route: 'tipping-vehicles',
      routeName: 'TippingVehicles',
      pageCode: 'AUTHRSC_PAGE_TIPPING_VEHICLES_HOME',
      widgetContainerId: 'asset-tv-home-container',
      title: 'route.tipping',
      assetTypeCode: AssetType.TippingVehicle,
    },
  ],
  [
    'TippingVehiclesInfo',
    {
      route: 'tipping-vehicles',
      routeName: 'TippingVehicles',
      pageCode: 'AUTHRSC_MOD_TIPPING_VEHICLES',
      widgetContainerId: '',
      title: 'route.tippingVehiclesInfo',
      assetTypeCode: AssetType.TippingVehicle,
    },
  ],
]);

export interface WidgetPermissionItem {
  claims?: string | string[];
  systemFeature?: { featureCode: SYSTEM_FEATURES; assetType?: AssetType };
}

// TODO: Clarify if the trip live feature should be on an asset type subscription and uncomment code if so
export const WIDGET_PERMISSIONS_MAP = new Map<string, WidgetPermissionItem>([
  [
    'AUTHRSC_PAGE_MCOMPACTOR_KPIS_AUTHRSC_WIDGET_TRIP_LOG',
    {
      systemFeature: {
        featureCode:
          SYSTEM_FEATURES.TripLive /*, assetType: ASSET_TYPE.MobileCompactor */,
      },
    },
  ],
  [
    'AUTHRSC_PAGE_MCOMPACTOR_ASSETINFO_AUTHRSC_WIDGET_TRIP_LOG',
    {
      systemFeature: {
        featureCode:
          SYSTEM_FEATURES.TripLive /*, assetType: ASSET_TYPE.MobileCompactor */,
      },
    },
  ],
  [
    'AUTHRSC_PAGE_TIPPING_VEHICLES_KPIS_AUTHRSC_WIDGET_TRIP_LOG',
    {
      systemFeature: {
        featureCode:
          SYSTEM_FEATURES.TripLive /*, assetType: ASSET_TYPE.MobileCompactor */,
      },
    },
  ],
]);
