
import { Component, Prop, Vue } from 'vue-property-decorator';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import {
  updateGeofence,
  activateGeofence,
  deprecateGeofence,
  getGeofenceById,
} from '@/api/geofence';
import {
  ASSET_TYPE_LIST,
  GEOFENCE_TYPE_CONFIG_LIST,
  HOURS_OF_THE_DAY,
} from '@/utils/workData/lookuptable';
import {
  customSuccessMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { TranslateResult } from 'vue-i18n';
import { TrackingDays } from '@/api/geofenceTypes';
import { sortWeekDays } from '@/utils';
import { useLoggedInUser } from '@/composables/context';
import { unref } from 'vue';
import { Organization } from '@/api/organizations';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import { UserModule } from '@/store/modules/user';
import { getOrgById } from '@/api/organizations';
type TrackingMode = 'GFNTRACK_OFF' | 'GFNTRACK_TIME_PERIOD' | 'GFNTRACK_ALWAYS';
type TrackingInterval =
  | 'GFNTRACK_ALL_DAYS'
  | 'GFNTRACK_INSIDE_TIME_INTERVAL'
  | 'GFNTRACK_OUTSIDE_TIME_INTERVAL'
  | null;
import { flattenOrganizations } from '@/composables/context';
import Treeselect from '@riophae/vue-treeselect';
import { checkFieldErrors } from './helpers/checkFieldErrors';

interface Normalizer {
  id: string;
  label: string;
  children: Organization[] | undefined;
}

interface FormData {
  name: string;
  organizationId: string;
  lifeCycle: string;
  associatedAssetTypes: string[];
  geofenceType: string;
  tracking: string;

  //optional
  trackingStartTime?: string;
  trackingEndTime?: string;
  trackingDays?: TrackingDays[];
  geofencePosition?: any;
}

@Component({
  name: 'EditGeofence',
  components: {
    BaseCard,
    LeafletMap,
    Treeselect,
  },
})
export default class extends Vue {
  @Prop({ required: true }) geofenceId!: string;

  /** Local variables */
  geofenceForm: any = {};
  geofenceConfig = {
    enableCreate: false,
    editOnly: false,
  };
  center: number[] = [46.771211, 23.623634];
  zoom: number = 10;
  show: boolean = false;
  geofenceOptions: any[] = GEOFENCE_TYPE_CONFIG_LIST;
  assetList: any[] = ASSET_TYPE_LIST;
  sts: string = 'info';
  editGeofencePageIsLoading = true;
  tracked: boolean = false;
  trackingMode: TrackingMode = 'GFNTRACK_OFF';
  trackingInterval: TrackingInterval = null;

  trackingModes: { id: TrackingMode; label: TranslateResult }[] = [
    {
      id: 'GFNTRACK_ALWAYS',
      label: this.$t('geofence.trackAlways'),
    },
    {
      id: 'GFNTRACK_TIME_PERIOD',
      label: this.$t('geofence.trackTimePeriod'),
    },
  ];

  trackingOptions: { id: TrackingInterval; label: TranslateResult }[] = [
    {
      id: 'GFNTRACK_INSIDE_TIME_INTERVAL',
      label: this.$t('geofence.inTimeInterval'),
    },
    {
      id: 'GFNTRACK_OUTSIDE_TIME_INTERVAL',
      label: this.$t('geofence.outTimeInterval'),
    },
    {
      id: 'GFNTRACK_ALL_DAYS',
      label: this.$t('geofence.allDay'),
    },
  ];
  ArrayTime: string[] = HOURS_OF_THE_DAY;
  daysAvailable = Object.keys(TrackingDays);
  organizations: Organization[] = [];
  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  organizationHierarchy: Organization[] = [];

  $refs!: {
    map: LeafletMap;
  };

  async created() {
    const loggedInUser = useLoggedInUser();
    const user = unref(loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    this.organizations = [user.organization];
    await this.fetchOrgById();
    await this.fetchGeofence();
  }

  /**
   * Get logged in user organization hierarhy
   */
  async fetchOrgById(): Promise<void> {
    try {
      const response = await getOrgById(UserModule.organizationId);
      this.organizationHierarchy = flattenOrganizations(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  getErrorForField(field: string) {
    if (this.errorInfos.length === 0) {
      return;
    }

    let fieldErrors = this.errorInfos.filter((error) => error.field === field);
    if (fieldErrors.length === 0) {
      return;
    }

    return fieldErrors.map((error) => {
      let key = `geofence.${error.code}.${error.field}`;
      let errorMessage = this.$t(key);
      if (errorMessage != key) {
        return errorMessage;
      }
      return error.message;
    })[0]; // enough space to display only 1 error
  }

  /**
   * Get geofence coordinates
   */
  getGeoCoord(): void {
    // only if the shape has been updated, set the new coordinates
    // map does not return anything here if no new shape has been drawn
    if (this.$refs.map.getGeofenceCoordinates().length) {
      this.geofenceForm.geofencePosition =
        this.$refs.map.getGeofenceCoordinates();
    }
  }

  getFinalGeofenceData(): FormData {
    this.editGeofencePageIsLoading = true;
    this.getGeoCoord();
    checkFieldErrors(this.errorInfos, {
      ...this.geofenceForm,
      tracked: this.tracked,
      trackingMode: this.trackingMode,
      trackingInterval: this.trackingInterval,
    });

    const finalGeofenceData: FormData = {
      name: this.geofenceForm.name,
      organizationId: this.geofenceForm.organizationId,
      lifeCycle: this.geofenceForm.lifeCycle,
      associatedAssetTypes: this.geofenceForm.associatedAssetTypes,
      geofenceType: this.geofenceForm.geofenceType,
      tracking: !this.tracked
        ? 'GFNTRACK_OFF'
        : this.trackingMode === 'GFNTRACK_ALWAYS'
        ? 'GFNTRACK_ALWAYS'
        : (this.trackingInterval as string),
    };

    if (this.geofenceForm.lifeCycle !== 'GFNLCL_ACTIVE') {
      finalGeofenceData.geofencePosition = this.geofenceForm.geofencePosition;
    }

    if (this.trackingMode === 'GFNTRACK_TIME_PERIOD') {
      finalGeofenceData.trackingDays = sortWeekDays(
        this.geofenceForm.trackingDays
      );

      if (this.trackingInterval !== 'GFNTRACK_ALL_DAYS') {
        finalGeofenceData.trackingStartTime =
          this.geofenceForm.trackingStartTime;
        finalGeofenceData.trackingEndTime = this.geofenceForm.trackingEndTime;
      }
    }

    return finalGeofenceData;
  }

  async saveGeofence(): Promise<void> {
    try {
      this.errorInfos = [];
      const finalGeofenceData = this.getFinalGeofenceData();

      const updateResponse = await updateGeofence(
        this.geofenceId,
        finalGeofenceData
      );
      if (!updateResponse) {
        customSuccessMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (updateResponse.data?.errors) {
        promptFailedBox(this.$t('common.update') as string);
        this.errorInfos = updateResponse.data.errors;
        return;
      }

      promptSuccessBox(this.$t('common.update') as string);
      this.errorInfos = [];
      this.$router.push(
        `/administration/index?activeName=geofences&geofence=${this.geofenceId}`
      );
    } catch (error) {
    } finally {
      this.editGeofencePageIsLoading = false;
    }
  }

  /**
   * Deprecate geofence entity
   */
  async deprecateGeofence(): Promise<void> {
    try {
      this.editGeofencePageIsLoading = true;
      await deprecateGeofence(this.geofenceForm.id).then((res) => {
        if (res.code === 200) {
          promptSuccessBox(this.$t('geofence.deprecate') as string);
          this.$router.push(
            `/administration/index?activeName=geofences&geofence=${this.geofenceId}`
          );
        } else {
          promptFailedBox(this.$t('geofence.deprecate') as string);
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.editGeofencePageIsLoading = false;
    }
  }

  /**
   * Activate geofence
   */
  async activateGeofence(): Promise<void> {
    try {
      this.editGeofencePageIsLoading = true;
      await activateGeofence(this.geofenceForm.id).then((res) => {
        if (res.code === 200) {
          promptSuccessBox(this.$t('geofence.activate') as string);
          this.$router.push(
            `/administration/index?activeName=geofences&geofence=${this.geofenceId}`
          );
        } else {
          promptFailedBox(this.$t('geofence.activate') as string);
        }
      });
    } catch (error) {
    } finally {
      this.editGeofencePageIsLoading = false;
    }
  }

  get organizationName() {
    return this.organizationHierarchy.find(
      ({ id }) => id === this.geofenceForm.organizationId
    )?.name;
  }

  /** Fetch functions */

  /**
   * Fetch geofence
   */
  async fetchGeofence(): Promise<void> {
    try {
      this.editGeofencePageIsLoading = true;
      await getGeofenceById(this.geofenceId).then((res) => {
        this.geofenceForm = res.data;
        this.trackingMode =
          this.geofenceForm.tracking === 'GFNTRACK_OFF'
            ? 'GFNTRACK_OFF'
            : this.geofenceForm.tracking === 'GFNTRACK_ALWAYS'
            ? 'GFNTRACK_ALWAYS'
            : ('GFNTRACK_TIME_PERIOD' as TrackingMode);
        this.trackingInterval =
          this.trackingMode === 'GFNTRACK_TIME_PERIOD'
            ? this.geofenceForm.tracking
            : (null as TrackingInterval);
        this.tracked = this.trackingMode !== 'GFNTRACK_OFF';
        this.editGeofencePageIsLoading = false;
        this.dataUpdate();
        this.geofenceConfig.enableCreate = [
          'GFNLCL_DRAFT',
          'GFNLCL_ACTIVE',
        ].includes(this.geofenceForm.lifeCycle);
      });
      this.$refs.map?.initializeGeofence([this.geofenceForm]);
      this.$refs.map?.setBoundsToGeofences();
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /** Styles && Layout functions */
  goBack(): void {
    this.$router.push(
      `/administration/index?activeName=geofences&geofence=${this.geofenceId}`
    );
  }

  /**
   * Update geofence item
   */
  dataUpdate(): void {
    if (this.geofenceForm.lifeCycle === 'GFNLCL_ACTIVE') {
      this.sts = 'success';
    } else if (this.geofenceForm.lifeCycle === 'GFNLCL_DRAFT') {
      this.sts = 'warning';
    } else if (this.geofenceForm.lifeCycle === 'GFNLCL_DEPRECATED') {
      this.sts = 'info';
    }
  }

  /**
   * Tree select normalizer key
   * @param node
   */
  customKeyNormalizer(node: Organization): Normalizer {
    return {
      id: node.id,
      label: node.name,
      children: node.children.length > 0 ? node.children : undefined,
    };
  }
}
