
import { Component, Vue } from 'vue-property-decorator';
import TableContainer from '@/components/table/TableContainer.vue';
import UserMgmt from './userMgmt/UserMgmt.vue';
import OrgMgmt from './orgMgmt/OrgMgmt.vue';
import AlarmConfigurationForCustomer from '../alarm/settingsForCustomer/AlarmConfigurationForCustomer.vue';
import Geofence from './geofence/Geofence.vue';
import { UserModule } from '@/store/modules/user';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';

@Component({
  name: 'Administration',
  components: {
    AlarmConfigurationForCustomer,
    Geofence,
    OrgMgmt,
    TableContainer,
    UserMgmt,
  },
})
export default class extends Vue {
  /** Local variables */
  activeName: string = 'userTab';
  menuAccess: any = {
    AUTHRSC_PAGE_USERMNG: false,
    AUTHRSC_PAGE_ORGMNG: false,
    AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION: false,
    AUTHRSC_PAGE_GEOFENCES: false,
  };

  async mounted() {
    var keys = Object.keys(this.menuAccess);
    for (let i = 0; i < keys.length; i++) {
      this.menuAccess[keys[i]] = await UserModule.hasMenu(keys[i]);
    }
    this.showTabs();
  }

  /**
   * Handle tab click
   */
  handleTabClick(): void {
    if (
      this.activeName === 'orgTab' &&
      this.$route.query.activeName !== 'orgTab'
    ) {
      this.$router.push('/administration/index?activeName=orgTab');
    } else if (
      this.activeName === 'userTab' &&
      this.$route.query.activeName !== 'userTab'
    ) {
      this.$router.push('/administration/index?activeName=userTab');
    } else if (
      this.activeName === 'geofences' &&
      this.$route.query.activeName !== 'geofences'
    ) {
      this.$router.push('/administration/index?activeName=geofences');
    } else if (
      this.activeName === 'alarmTab' &&
      this.$route.query.activeName !== 'alarmTab'
    ) {
      this.$router.push('/administration/index?activeName=alarmTab');
    }
  }

  /**
   * Show default tab view by route query or by page authorization code
   */
  async showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = this.hasMenu('AUTHRSC_PAGE_USERMNG')
        ? 'userTab'
        : this.hasMenu('AUTHRSC_PAGE_ORGMNG')
        ? 'orgTab'
        : this.hasMenu('AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION')
        ? 'alarmTab'
        : this.hasMenu('AUTHRSC_PAGE_GEOFENCES')
        ? 'geofences'
        : '';
    }
  }

  /**
   * Check if has menu access for showing tab
   * @param menu
   */
  hasMenu(menu: string) {
    return this.menuAccess[menu];
  }

  /** Should not allow tab GEOFENCE and ALARM SETTINGS to be visible for company type of body builder or dealer even if there are claims */
  get tabNotVisibleForBodyBuilderAndDealer() {
    return ![COMPANY_TYPE.BodyBuilder, COMPANY_TYPE.Dealer].includes(
      UserModule.companyType
    );
  }
}
