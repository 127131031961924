
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getOrgById } from '@/api/organizations';
import { getUsers } from '@/api/users';
import { updateActivationStatus } from '@/api/organizations';
import { USER_COLS } from '@/utils/workData/userMgmt';
import { ASSETS_COLS } from '@/utils/workData/assetsMgmt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import {
  promptSuccessBox,
  promptFailedBox,
  customFailedMessage,
} from '@/utils/prompt';
import NewCard from '@/components/cusCard/NewCard.vue';
import OrgInfo from './components/OrgInfo.vue';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import { getAssets } from '@/api/assets';
import { UserModule } from '@/store/modules/user';
import { generateUrl } from '@/utils/index';
import PureTable from '@/components/table/PureTable.vue';
import { helpdeskCompanyTypes } from './organizationManagementConstants';
import { isCompanyTypeOf } from '@/utils/companyService';
import {
  getAccessibleServiceRegions,
  AccessibleServiceRegionResponse,
  AccessibleServiceRegionsField,
} from '@/api/serviceRegions';
import { PageModule } from '@/store/modules/page';
import { ORGANIZATION_ID } from '@/utils/constants';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  Sorter,
  Pagination,
  addFilter,
  setFilters,
  setSorters,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';

@Component({
  name: 'ViewOrganizationInfo',
  components: {
    NewCard,
    OrgInfo,
    PureTable,
    DeactivateDialog,
  },
})
export default class extends Vue {
  @Prop() orgId!: string;

  /** Local variables */
  editBtnAuth: string[] = ['AUTHRSC_MOD_ORG_EDIT'];
  deacBtnAuth: string[] = ['AUTHRSC_MOD_ORG_DEACT'];
  title: string = '';
  currentOrgInfo: any = {};
  activateOrDeactivate: string = '';
  activeName: string = 'first';
  userTotal: number = 0;
  userList = [];
  userCols = USER_COLS;
  assetTotal: number = 0;
  assetList = [];
  assetCols = ASSETS_COLS;
  visiable: boolean = false;
  dialogContent: any = '';
  isOrganizationViewPageLoading: boolean = false;
  loadingText: string = this.$t('userModule.loadingOrganizationData') as string;
  notAllowedActionsByHimSelf: string[] = ['DEACTIVATE'];
  helpdeskCompanyTypes: string[] = helpdeskCompanyTypes;
  isHyvaHelpdeskAdmin: boolean = false;
  serviceRegionsTextValue: string = '';

  created() {
    this.isHyvaHelpdeskAdmin = isCompanyTypeOf(this.helpdeskCompanyTypes);
    if (this.orgId) {
      if (this.isHyvaHelpdeskAdmin) {
        this.getAccessibleServiceRegions();
      } else {
        this.fetchOrganizationById();
      }
    }
  }

  handleEdit() {
    if (this.isHyvaHelpdeskAdmin) {
      this.$router.push(`/administration/edit-helpdesk-org/${this.orgId}`);
    } else {
      this.$router.push(`/administration/edit-org/${this.orgId}`);
    }
  }

  handleCancel() {
    this.visiable = false;
  }

  getActiveName() {
    return this.activeName;
  }

  /**
   * Show deactivate organization
   */
  showDeactive(): void {
    if (this.selfCheckNotAllowedAction('DEACTIVATE')) {
      return;
    }
    this.visiable = true;
    if (
      this.currentOrgInfo.activationStatus === ACTIVATION_STATUS.Deactivated
    ) {
      this.dialogContent = this.$t('userModule.activateInfo', {
        name: this.title,
      });
    } else {
      this.dialogContent = this.$t('userModule.deactivateInfo', {
        name: this.title,
      });
    }
    // this.dialogContent = this.$t('organizationModule.deactivateInfo', {'name': this.title});
  }

  async handleDeactive() {
    let infoMsg = (
      this.activateOrDeactivate === 'deactivate'
        ? this.$t('common.deactivate')
        : this.$t('common.activate')
    ) as string;

    await updateActivationStatus(this.orgId, {
      activationStatus:
        this.activateOrDeactivate === 'deactivate'
          ? ACTIVATION_STATUS.Deactivated
          : ACTIVATION_STATUS.Activated,
    }).then((res) => {
      if (res.code === 200) {
        promptSuccessBox(infoMsg);

        // this.currentOrgInfo.activationStatus = ACTIVATION_STATUS.Deactivated;
        this.$router.push('/administration/index?activeName=orgTab');
      } else if (res.code === 400) {
        promptFailedBox(infoMsg);
      }
    });
    this.visiable = false;
  }

  handleClick() {}

  async handleUserPage(page: number, pageSize: number) {
    let filterParams = {
      page: page,
      size: pageSize,
      searchFieldName: ORGANIZATION_ID,
      searchFieldValues: this.orgId,
    };

    await getUsers(generateUrl(filterParams)).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200 && res.data) {
        let resData = res.data;
        resData.users.forEach((item: any) => {
          item.userVerified = item.emailVerified
            ? 'userModule.yes'
            : 'userModule.no';
        });

        this.userTotal = resData.total;
        this.userList = resData.users;
      }
    });
  }

  async handleAssetPage(page: number, pageSize: number) {
    let filterParams = {
      companyId: UserModule.companyId,
      page: page,
      size: pageSize,
    };
    await getAssets(generateUrl(filterParams)).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.assetTotal = res.data.total;
        this.assetList = res.data.assets;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  async fetchOrganizationById() {
    this.isOrganizationViewPageLoading = true;
    await getOrgById(this.orgId).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.currentOrgInfo = res.data;
        PageModule.setTitle(this.currentOrgInfo.name);
        if (
          this.currentOrgInfo.activationStatus === ACTIVATION_STATUS.Deactivated
        ) {
          this.activateOrDeactivate = 'activate';
        } else {
          this.activateOrDeactivate = 'deactivate';
        }

        this.isOrganizationViewPageLoading = false;
        return;
      }

      this.isOrganizationViewPageLoading = false;
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });

    let userFilterParams = {
      page: 1,
      size: UserModule.gridPageSize,
      searchFieldName: 'organizationId',
      searchFieldValues: this.orgId,
    };
    await getUsers(generateUrl(userFilterParams)).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        let resData = res.data;
        resData.users.forEach((item: any) => {
          item.userVerified = item.emailVerified
            ? 'userModule.yes'
            : 'userModule.no';
        });

        this.userTotal = resData.total;
        this.userList = resData.users;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });

    let assetFilterParams = {
      searchFieldName: 'organizationId',
      searchFieldValues: [this.orgId],
      companyId: UserModule.companyId,
      page: 1,
      size: UserModule.gridPageSize,
    };
    await getAssets(generateUrl(assetFilterParams)).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.assetTotal = res.data.total;
        this.assetList = res.data.assets;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  /** Get accessible service regions for organization id, use case: logged in as helpdesk */
  async getAccessibleServiceRegions() {
    try {
      this.isOrganizationViewPageLoading = true;
      const filter: Filter = {
        name: ORGANIZATION_ID,
        operator: FilterOperator.EQUAL,
        value: [this.orgId],
      };
      const pagination: Pagination = { page: 1, size: 1000 };
      const queryParameter: QueryParameter = {
        filters: [filter],
        sorters: [],
        pagination: pagination,
      };
      const response = await getAccessibleServiceRegions(queryParameter);
      const organizationAccessibleserviceRegions: AccessibleServiceRegionsField[] =
        response.data.find(
          (item: AccessibleServiceRegionResponse) =>
            item.organizationId == this.orgId
        )?.accessibleServiceRegions!;
      if (organizationAccessibleserviceRegions) {
        this.serviceRegionsTextValue =
          organizationAccessibleserviceRegions[0].name;
        for (let i = 1; i < organizationAccessibleserviceRegions.length; i++) {
          this.serviceRegionsTextValue +=
            ', ' + organizationAccessibleserviceRegions[i].name;
        }
      }

      this.fetchOrganizationById();
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Self check not allowed actions like removed or deactivated organization associated to logged in user himself
   */
  selfCheckNotAllowedAction(action: string): boolean {
    if (
      this.currentOrgInfo.id === UserModule.organizationId &&
      this.notAllowedActionsByHimSelf.includes(action)
    ) {
      const errorMessage: string =
        action === 'DEACTIVATE' &&
        this.currentOrgInfo.activationStatus === ACTIVATION_STATUS.Activated
          ? (this.$t(
              'administrationModule.notAllowedToDeactivateYourOwnOrganization'
            ) as string)
          : action === 'DEACTIVATE' &&
            this.currentOrgInfo.activationStatus ===
              ACTIVATION_STATUS.Deactivated
          ? (this.$t(
              'administrationModule.notAllowedToActivateYourOwnOrganization'
            ) as string)
          : '';
      customFailedMessage(errorMessage);
      return true;
    }
    return false;
  }

  /*
   * Do not allow self action on own entity like organization to disable it
   * @return boolean
   */
  get checkIfAllowedSelfAction(): boolean {
    return this.currentOrgInfo.id != UserModule.organizationId;
  }
}
