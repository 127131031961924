import i18n from '@/lang';
import { TreeData } from 'element-ui/types/tree';

// TODO-TYPING: Add type to modules parameter
export function modulesToTreeData(
  modules: any,
  rootParentCode?: string,
  disabled?: boolean
): TreeData {
  disabled ??= false;
  const recurse = (parentCode: string) => {
    let arr: any = [];
    modules
      .filter((item: any) => item.parentCode === parentCode)
      .forEach((item: any) => {
        arr.push({
          id: item.id,
          label: i18n.tc(item.code),
          name: item.code,
          disabled,
          children: recurse(item.code),
        });
      });
    return arr;
  };
  return recurse(rootParentCode ?? ''); // The father id of the first level node is an empty string
}
