import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { getUserById, updateUser } from '@/api/users';
import {
  getCompanySubscriptionsByCompanyId,
  Subscription,
} from '@/api/subscriptionPackages';
import { getToken, removeToken, setToken } from '@/utils/cookies';
import router, { resetRouter } from '@/router';
import i18n from '@/lang';
import store from '@/store';
import Vue from 'vue';
import moment from 'moment-timezone';
import {
  AssetType,
  COMPANY_TYPE,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { getOrgById, Organization } from '@/api/organizations';
import { Claims, RawClaim, RawClaims } from './claims';

export interface IUserState {
  token: string;
  id: string;
  name: string;
  avatar?: string;
  introduction?: string;
  companyType: string;
  menus?: string[];
  roles?: string[];
  role?: string;
  email?: string;
  companyId: string;
  organizationId: string;
  i18nCode: string;
  theme: number;
  logo: any;
  timeZone: string;
  datetimeFormat: string;
  gridPageSize: number;
  policyRegion: string;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || '';
  public name = '';
  public id = '';
  public avatar = '';
  public introduction = '';
  public roles: string[] = [];
  public email = '';
  public menus: string[] = [];
  public companyId = '';
  public organizationId = '';
  /**
   * @deprecated Use `useLoggedInUser()` instead.
   */
  public organization: Organization = {} as any; // otherwise the getter isn't generated...
  public companyType: COMPANY_TYPE = COMPANY_TYPE.Customer;
  public claims = new Claims([]);
  public i18nCode = '';
  public theme = 0;
  public logo = null;
  public timeZone = moment.tz.guess();
  public datetimeFormat = 'YYYY-MM-DD kk:mm:ss';
  public gridPageSize = 15;
  public role = '';
  public subscriptions: Subscription[] = [];
  public activationStatus: string = '';
  public policyRegion: string = '';

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Mutation
  private SET_ID(id: string) {
    this.id = id;
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar;
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction;
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  @Mutation
  private SET_ROLE(role: string) {
    this.role = role;
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email;
  }

  @Mutation
  private SET_MENUS(menus: string[]) {
    this.menus = menus;
  }

  @Mutation
  private SET_COMPANYID(companyId: string) {
    this.companyId = companyId;
  }

  @Mutation
  private SET_ORGID(orgId: string) {
    this.organizationId = orgId;
  }

  @Mutation
  private SET_ORGANIZATION(org: Organization) {
    this.organization = org;
  }

  @Mutation
  private SET_COMPANYTYPE(companyType: COMPANY_TYPE) {
    this.companyType = companyType;
  }

  @Mutation
  private SET_CLAIMS(claims: Claims) {
    this.claims = claims;
  }

  @Mutation
  private SET_I18NCODE(i18nCode: string) {
    this.i18nCode = i18nCode;
  }

  @Mutation
  private SET_THEME(theme: number) {
    this.theme = theme;
  }

  @Mutation
  private SET_LOGO(logo: any) {
    this.logo = logo;
  }

  @Mutation
  private SET_TIMEZONE(timeZone: string) {
    this.timeZone = timeZone;
  }

  @Mutation
  private SET_SUBSCRIPTIONS(subscriptions: Subscription[]) {
    this.subscriptions = subscriptions;
  }

  @Mutation
  private SET_ACTIVATION_STATUS(activationStatus: string) {
    this.activationStatus = activationStatus;
  }

  @Mutation
  private SET_POLICY_REGION(policyRegion: string) {
    this.policyRegion = policyRegion;
  }

  @Mutation
  private SET_DATEFORMAT(datetimeFormat: string) {
    this.datetimeFormat = datetimeFormat;
  }

  @Mutation
  private SET_PAGESIZE(pageSize: number) {
    this.gridPageSize = pageSize;
  }

  @Action({ rawError: true })
  @Action
  public SetLang(i18nCode: string) {
    this.SET_I18NCODE(i18nCode);
  }

  @Action
  public async keycloakLogin(accessToken: string) {
    this.saveToken(accessToken);

    // to update login information (last login time and email verified)
    if (Vue.prototype.$keycloak && Vue.prototype.$keycloak.authenticated) {
      this.SET_ID(Vue.prototype.$keycloak.subject);
    }
  }

  @Action
  public saveToken(accessToken: string) {
    setToken(accessToken);
    this.SET_TOKEN(accessToken);
  }

  @Action
  public ResetToken() {
    removeToken();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }

  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!');
    }
    const { data } = await getUserById(this.id);

    if (!data) {
      throw Error('Verification failed, please Login again.');
    }
    const {
      companyId,
      companyType,
      organizationId,
      claims: claims,
      modules,
      pages,
      i18nCode,
      theme,
      username,
      email,
      setting,
      role,
      activationStatus,
      policyRegion,
      emailVerified,
    } = data;
    //const roles = ["admin"];
    // const roles = ["editor"];
    // roles must be a non-empty array
    // if (!roles || roles.length <= 0) {
    //   console.log('keycloakGetUserInfo: roles must be a non-null array!')
    //   return
    // }

    if (!companyType) {
      console.log('keycloakGetUserInfo: Company type must be a non-null!');
      throw Error('keycloakGetUserInfo: Company type must be a non-null!');
    }

    if (!emailVerified) {
      const emailIsVerifiedRequestBody = {
        email: email,
        emailVerified: true,
      };
      updateUser(this.id, emailIsVerifiedRequestBody);
    }

    const { data: loggedInOrganization } = await getOrgById(organizationId);

    let pageList: string[] = [];
    if (pages) {
      pageList = pages.map((item: any) => item.code);
    } else {
      console.log('keycloakGetUserInfo: Pages must be a non-null!');
      throw Error('keycloakGetUserInfo: Pages must be a non-null!');
    }

    if (modules) {
      modules.forEach((module: any) => pageList.push(module.code));
    } else {
      console.log('keycloakGetUserInfo: Modules must be a non-null!');
      throw Error('keycloakGetUserInfo: Modules must be a non-null!');
    }

    if (!claims) {
      console.log('keycloakGetUserInfo: Claims must be a non-null!');
      throw Error('keycloakGetUserInfo: Claims must be a non-null!');
    }

    let subscriptionResponse = await getCompanySubscriptionsByCompanyId(
      data.companyId
    );
    let subscription = subscriptionResponse.data || [];

    // Legacy structure of claims, where it only keeps the resource names,
    // but discards the actions. For backward compatibility, it also
    // builds a list of pages if it wasn't provided by the server.
    // TODO This piece of code should be thrown away in the future.
    const claimList: string[] = [];
    const addClaimsToPages = pageList.length === 0;
    claims.forEach((item: RawClaim) => {
      claimList.push(item.resource);
      if (addClaimsToPages) {
        pageList.push(item.resource);
      }
    });

    i18n.locale = i18nCode;
    document.documentElement.lang = i18nCode;

    if (setting) {
      this.SET_TIMEZONE(setting.timeZone);
      this.SET_DATEFORMAT(setting.datetimeFormat);
      this.SET_PAGESIZE(setting.gridPageSize);
    }

    this.SET_ROLES([role]);
    this.SET_ROLE(role);
    this.SET_COMPANYID(companyId);
    this.SET_COMPANYTYPE(companyType);
    this.SET_ORGID(organizationId);
    this.SET_ORGANIZATION(loggedInOrganization);
    this.SET_MENUS(pageList);
    this.SET_CLAIMS(new Claims(claims as RawClaims));
    this.SET_I18NCODE(i18nCode);
    this.SET_THEME(theme);
    this.SET_NAME(username);
    this.SET_EMAIL(email);
    this.SET_SUBSCRIPTIONS(subscription);
    this.SET_ACTIVATION_STATUS(activationStatus);
    this.SET_POLICY_REGION(policyRegion);
  }

  @Action
  public hasMenu(resourceName: string) {
    return this.menus.includes(resourceName);
  }

  @Action
  public hasSystemFeature(
    requiredFeature: SYSTEM_FEATURES | [SYSTEM_FEATURES | null, AssetType]
  ): boolean {
    let subscriptions = this.subscriptions;

    let featureCode: SYSTEM_FEATURES | null = null;
    let assetType: AssetType = AssetType.All;

    if (Array.isArray(requiredFeature)) {
      featureCode = requiredFeature[0];
      assetType = requiredFeature[1];
    } else {
      featureCode = requiredFeature;
    }

    if (assetType != AssetType.All) {
      subscriptions = subscriptions.filter(
        (sub) => sub.subscriptionPackageAssetType == assetType
      );
    }

    if (featureCode === null) {
      return Boolean(subscriptions.length);
    }

    return subscriptions
      .flatMap((sub) => sub.systemFeatures)
      .some(
        (feature) =>
          feature.code == featureCode && feature.value == true.toString()
      );
  }

  @Action
  public UpdateUserName(nameUpdated: string) {
    this.SET_NAME(nameUpdated);
  }

  @Action
  public UpdateTimeZone(timeZone: string) {
    this.SET_TIMEZONE(timeZone);
  }

  // @Action
  // public async ChangeRoles(role: string) {
  //   // Dynamically modify permissions
  //   const token = role + '-token'
  //   this.SET_TOKEN(token)
  //   setToken(token)
  //   await this.GetUserInfo()
  //   resetRouter()
  //   // Generate dynamic accessible routes based on roles
  //   PermissionModule.GenerateRoutes(this.roles)
  //   // Add generated routes
  //   router.addRoutes(PermissionModule.dynamicRoutes)
  //   // Reset visited views and cached views
  // }

  @Action
  public async keycloakLogout() {
    if (router.currentRoute.fullPath !== '/home') {
      router.replace({ path: '/' });
      location.reload();
    }

    await Vue.prototype.$keycloak.logout();
    removeToken();
    resetRouter();
    // Reset visited views and cached views
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }
}

export const UserModule = getModule(User);
