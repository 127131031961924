
import { Component, Vue } from 'vue-property-decorator';
import {
  getOrganizations,
  getOrgById,
  Organization,
  OrganizationResponse,
} from '@/api/organizations';
import {
  ORG_MANAGEMENT_COLS,
  ORG_MANAGEMENT_SEARCH_FIELDS,
  SERVICE_REGION_PROPERTY_NAME,
} from '@/utils/workData/orgMgmt';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage } from '@/utils/prompt';
import Treeselect from '@riophae/vue-treeselect';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';
import { helpdeskCompanyTypes } from './organizationManagementConstants';
import { isCompanyTypeOf } from '@/utils/companyService';
import { UserRole } from '@/utils/workData/lookuptable';
import { ServiceRegionModel } from './models/serviceRegionModel';
import { getAllServiceRegionsList } from '@/utils/serviceRegions';
import { LoggedInUserRef, useLoggedInUser } from '@/composables/context';
import { unref } from 'vue';

@Component({
  name: 'OrgMgmt',
  components: {
    Treeselect,
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  tableData: OrganizationResponse = [];
  totalOrg: number = 0;
  treeProp = { children: 'children', hasChildren: 'hasChildren' };
  isOrganizationManagementLoading: boolean = false;
  cols = ORG_MANAGEMENT_COLS;
  orgSearchFieldList = ORG_MANAGEMENT_SEARCH_FIELDS;
  isAdmin: boolean = false;
  allServiceRegions: ServiceRegionModel[] = [];
  helpdeskPropertyNames: string[] = [SERVICE_REGION_PROPERTY_NAME];

  testValue: string = '';
  options = [{ id: 1 }, { id: 2 }, { id: 3 }];

  searchObj: any = {
    reference: null,
    value: null,
  };

  helpdeskCompanyTypes: string[] = helpdeskCompanyTypes;
  loggedInUser!: LoggedInUserRef;

  getTotalOrg(root = this.tableData) {
    let count = root.length;

    for (const { children } of root) {
      count += this.getTotalOrg(children);
    }

    return count;
  }

  generateUrl = (filterParams: any) => {
    let result = '';
    for (let item in filterParams) {
      if (filterParams[item] && String(filterParams[item])) {
        result = result + `&${item}=` + encodeURIComponent(filterParams[item]);
      }
    }

    return result;
  };

  getFilterUrl(col: string, order: string) {
    let filterParams: any = {
      orderBy: col,
      order: order,
    };

    let key: string = this.searchObj.reference;
    let value: string = this.searchObj.value;

    if (key && value) {
      filterParams[key] = value;
    }

    return this.generateUrl(filterParams);
  }

  async fetchOrgMgmtInfos() {
    this.isOrganizationManagementLoading = true;
    // This can probably just be taken directly from loggedInUser.organization, but
    // esp when orgs change, that info must then be reloaded from the server. So for now,
    // let's just always reload.
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    await getOrgById(user.organization.id).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.tableData = [res.data];
        this.totalOrg = this.getTotalOrg();

        if (this.isAdmin) {
          this.manageOrganizationsRegionNames();
        }

        this.handleParentName(this.tableData);
        this.isOrganizationManagementLoading = false;
        return;
      }

      this.isOrganizationManagementLoading = false;
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  manageOrganizationsRegionNames() {
    this.tableData.forEach((org: any) => {
      org.serviceRegion = this.getOrganizationRegionNames(org.serviceRegion);

      if (org.children && org.children.length > 0) {
        org.children.forEach((childOrg: any) => {
          childOrg.serviceRegion = this.getOrganizationRegionNames(
            childOrg.serviceRegion
          );
        });
      }
    });
  }

  getOrganizationRegionNames(serviceRegionCodes: string[]) {
    if (!serviceRegionCodes) return;

    let regions = '';
    serviceRegionCodes.forEach((regionCode: string) => {
      const regionName = this.getRegionNameByCode(regionCode);
      if (regionName) {
        if (!!regions == false) {
          regions = regionName;
        } else {
          regions += ', ' + regionName;
        }
      }
    });

    return regions;
  }

  getRegionNameByCode(regionCode: string) {
    if (this.allServiceRegions.hasOwnProperty('errors')) return;
    return this.allServiceRegions.find(
      (region: ServiceRegionModel) => region.serviceRegionCode == regionCode
    )?.serviceRegionName;
  }

  async handleFilter(col: string, order: string) {
    let paramsUrl: string = this.getFilterUrl(col, order);
    const companyId = unref(this.loggedInUser)?.companyId;
    if (!companyId) {
      // Shouldn't ever happen
      throw new Error('No user logged in');
    }
    this.getOrgs(companyId, paramsUrl);
  }

  handleParentName(orgs: OrganizationResponse) {
    if (orgs.length === 0) return;
    orgs.forEach((item: Organization) => {
      item.parentName = item.parentName ? item.parentName : '—';
      if (item.timezone) {
        item.timezone = item.timezone.replace('/', '_');
      }
      return this.handleParentName(item.children);
    });
  }

  customKey(node: any) {
    return {
      id: node.id,
      label: node.name,
      children: node.children.length > 0 ? node.children : undefined,
    };
  }

  goToCreateOrganizationFormPage() {
    if (isCompanyTypeOf(this.helpdeskCompanyTypes)) {
      this.$router.push('add-new-helpdesk-org');
    } else {
      this.$router.push('add-new-org');
    }
  }

  async getOrgs(companyId: string, filter?: string) {
    try {
      this.isOrganizationManagementLoading = true;
      const response = await getOrganizations(companyId, filter);
      this.tableData = response.data;
      this.totalOrg = this.getTotalOrg();

      if (this.isAdmin) {
        this.manageOrganizationsRegionNames();
      }

      this.handleParentName(this.tableData);
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isOrganizationManagementLoading = false;
    }
  }

  async getAllServiceRegionsAndFetchOrganizations() {
    await getAllServiceRegionsList().then((data: any) => {
      this.allServiceRegions = data;

      this.fetchOrgMgmtInfos();
    });
  }

  created() {
    this.loggedInUser = useLoggedInUser();

    // TODO Change to claims mechanism
    if (UserModule.role === UserRole.HelpdeskAdmin) {
      this.isAdmin = true;
      this.helpdeskPropertyNames.forEach((property: string) => {
        const column = this.cols.find((col: any) => col.prop == property);

        if (column) {
          column.visible = true;
        }
      });

      this.getAllServiceRegionsAndFetchOrganizations();
    } else {
      this.helpdeskPropertyNames.forEach((property: string) => {
        const columnIndex = this.cols.findIndex(
          (col: any) => col.prop == property
        );

        if (columnIndex > -1) {
          this.cols.splice(columnIndex, 1);
        }
      });

      this.fetchOrgMgmtInfos();
    }
  }
}
