
import { Component, Prop, Vue } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { getOrgById, Organization } from '@/api/organizations';
import { ErrorType } from '@/api/types';
import CommonBtn from '@/components/button/CommonBtn.vue';
import {
  COUNTRY_TYPE_LIST,
  ACTIVATION_STATUS,
} from '@/utils/workData/lookuptable';
import { UserModule } from '@/store/modules/user';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { customFailedMessage } from '@/utils/prompt';
import { filterDeactivatedOrg } from '@/utils/index';
import { HelpdeskOrganizationModel } from '../models/helpdeskOrganizationModel';
import { NotAllocatedAccessibleServiceRegionsResponse } from '@/api/serviceRegions';
import { flattenOrganizations } from '@/composables/context';
import { getTimezones, TimezoneResponse } from '@/api/timezone';

@Component({
  name: 'NewHelpeskOrganization',
  components: {
    Treeselect,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop({ required: true }) orgForm!: HelpdeskOrganizationModel;
  @Prop({ required: true }) disabledTreeSel!: boolean;
  @Prop({ required: false }) saveOrCreate!: boolean;
  @Prop() errorInfos!: ErrorType[];
  @Prop() serviceRegionsList!: NotAllocatedAccessibleServiceRegionsResponse[];
  @Prop() orgId!: string;

  orgInfos: Organization[] = [];
  countries = COUNTRY_TYPE_LIST;
  errorCode = ERROR_CODE_LIST;
  timeZoneOptions: TimezoneResponse[] = [];
  isOrganizationDetailsLoading: boolean = true;
  allOrganizations: Organization = {
    id: '',
    companyId: '',
    parentId: '',
    name: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    assetsCount: 0,
    usersCount: 0,
    subOrganizationCount: 0,
    timezone: '',
    children: [],
    note: '',
    serviceRegion: [],
    street: '',
    district: '',
    province: '',
    city: '',
    country: '',
    additionalInfo: '',
    zipCode: '',
    isDeleted: false,
    parentName: '',
  };

  validateParentId = (rule: any, value: any, callback: any) => {
    if (this.orgForm.parentId) {
      callback();
    } else {
      callback(new Error(`${this.$t('organizationModule.electOrg')}`));
    }
  };

  get inlineMsg() {
    return false;
  }

  get rules() {
    const rules = {
      name: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputOrgName'),
          trigger: 'change',
        },
      ],
      country: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputCountry'),
          trigger: 'blur',
        },
      ],
      timezone: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputTimeZone'),
          trigger: 'blur',
        },
      ],
    };

    return rules;
  }

  handleSelect() {
    (this.$refs.orgForm as any).validateField('parentId');
  }

  handleTreeselectOpen() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(-90deg)');
  }

  handleTreeselectClose() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(90deg)');
  }

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('organizationModule.duplicateName')}`;
    }

    return errInfo;
  }

  handleInput() {
    this.$emit('handle-input');
  }

  saveHelpdeskOrg() {
    (this.$refs.orgForm as any).validate((valid: any) => {
      if (valid) {
        this.$emit('save-helpdesk-org');
      }
    });
  }

  async fetchOrgMgmtInfos() {
    try {
      this.isOrganizationDetailsLoading = true;
      const organizationResponse = await getOrgById(UserModule.organizationId);
      this.allOrganizations = organizationResponse.data;
      this.orgInfos = filterDeactivatedOrg([this.allOrganizations]);
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isOrganizationDetailsLoading = false;
    }
  }

  customKey(node: any) {
    if (node.children && !node.children.length) {
      delete node.children;
    }
    return {
      id: node.id,
      label: node.name,
      children: node.children,
    };
  }

  created() {
    getTimezones().then(({ data }) => (this.timeZoneOptions = data));
    this.fetchOrgMgmtInfos();
  }

  /**
   * Get current organization parent name for subdivision of read only field
   */
  get currentOrgParentName(): string {
    const flatOrgs: Organization[] = flattenOrganizations(
      this.allOrganizations
    );
    const finalOrg = flatOrgs.find(
      (org: Organization) => org.id === this.orgId
    );
    return finalOrg?.parentName || '';
  }
}
