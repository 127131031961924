import { Asset } from '@/api/geofenceTypes';
import {
  EntityType,
  KPI_FIELDS,
  KPI_UNIT,
  OperationalStatus,
} from './workData/lookuptable';
import { KpiParametersValues } from '@/api/assetsAll';
import { KpiDataField, KpiDataResponse, KpiDataValue } from '@/api/assets';

export function findAssets(organization: any): Asset[] {
  const result = organization.assets;

  for (const sub of organization.suborganizations) {
    result.push(...findAssets(sub.organization));
  }
  return result;
}

export function findOrganizationIds(organization: any): string[] {
  return [
    organization.id,
    ...organization.suborganizations.flatMap((sub: any) =>
      findOrganizationIds(sub.organization)
    ),
  ];
}

export function findAssetKpiDataValue(
  data: KpiDataResponse,
  code: typeof KPI_FIELDS.DeployingNewRelease
): boolean | undefined;
export function findAssetKpiDataValue(
  data: KpiDataResponse,
  code: typeof KPI_FIELDS.NewSystemReleaseAvailable
): boolean | undefined;
export function findAssetKpiDataValue(
  data: KpiDataResponse,
  code: typeof KPI_FIELDS.SystemReleaseVersion
): string | undefined;
export function findAssetKpiDataValue(
  data: KpiDataResponse,
  code: typeof KPI_FIELDS[keyof typeof KPI_FIELDS]
): string | boolean | any | undefined;

export function findAssetKpiDataValue(
  data: KpiDataResponse,
  code: typeof KPI_FIELDS[keyof typeof KPI_FIELDS]
): string | boolean | any | undefined {
  const details = data?.details.find((x) => x.entity === EntityType.ASSET);
  const field = details?.fields?.find((x) => x.code === code);
  const value = field?.values?.at(0)?.v ?? undefined;
  if (value == undefined) {
    return undefined;
  }
  switch (field?.unit) {
    case KPI_UNIT.Boolean:
      return value === 'true';
    default:
      return value;
  }
}

export function findKpiValue(
  kpis: KpiParametersValues[],
  asset: string | { id: string },
  code: string
) {
  if (!kpis || !Array.isArray(kpis)) {
    return;
  }

  const assetId = typeof asset === 'string' ? asset : asset.id;
  const value = kpis.find((kpi) => kpi.assetId === assetId);

  if (!value) {
    return;
  }

  return value.values.find((v: any) => v.code === code)?.v;
}

// TODO-TYPING: Add type for the config parameter
export function getAssetStatusConfig(
  type: OperationalStatus,
  option: 'color' | 'icon',
  config: any
) {
  const configuration = config.status.find(
    (s: { type: OperationalStatus }) => s.type === type
  );

  return configuration?.[option];
}
