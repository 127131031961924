export type AuthorizableResourceCode = string;
export type AuthorizableActionCode = string;

export interface RawClaim {
  resource: AuthorizableResourceCode;
  actions: AuthorizableActionCode[];
}

export type RawClaims = Array<RawClaim>;

export const AUTHACT_ACCESS_GRANTED = 'AUTHACT_ACCESS_GRANTED';

export class Claims {
  private _claims: Map<string, Set<AuthorizableActionCode>>;

  constructor(claims: RawClaims) {
    // Convert the raw JSON structure from the server into a more
    // efficiently usable representation.
    this._claims = new Map();
    for (const rawClaim of claims) {
      const claim = new Set(rawClaim.actions);
      this._claims.set(rawClaim.resource, claim);
    }
  }

  public hasClaim(
    resource: AuthorizableResourceCode,
    action: AuthorizableActionCode = AUTHACT_ACCESS_GRANTED
  ): boolean {
    return this._claims.get(resource)?.has(action) ?? false;
  }
}
