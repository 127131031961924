import i18n from '@/lang';
import {
  KPI_UNIT,
  OIL_LEVELS_NUMBER_MAP,
  REAL_TIME_KPIS_METRICS,
} from '@/utils/workData/lookuptable';
import { CustomizedKpiLimit } from '@/api/alarmConfig';
import { ValueLimitCheck } from '@/api/value';
import { KpiDataField, StatisticalKpiData } from '@/api/assets';
import { TemplateResponse } from '@/api/report';

const COLORS = new Map<string, string>([
  ['RED', '#E04642'],
  ['ORANGE', '#E39D00'],
  ['GREEN', '#4DAF70'],
  ['DEFAULT', '#373E41'],
]);

export type KpisDataPayload = {
  template: TemplateResponse;
  kpis: StatisticalKpiData;
};

export const collateExpandTableData = (
  handleData: any,
  kpiTypeList: any,
  firstColType: string
) => {
  let firstCol = firstColType;
  let cols: any = [
    {
      label: firstCol === 'date' ? 'KPI.date' : 'KPI.asset',
      unit: null,
      prop: firstCol,
      required: true,
      visible: true,
      sortable: true,
    },
  ];
  let tableList: any = [];
  for (let i = 0; i < handleData[0].values.length; i++) {
    tableList.push({});
  }
  kpiTypeList.forEach((item: any) => {
    cols.push({
      label: item.tableHeadLabel,
      prop: item.tableProp,
      unit: item.unit,
      required: true,
      visible: true,
      sortable: true,
    });
    let filterArray = handleData.filter((dataItem: any) => {
      return dataItem.code === item.code;
    });
    let key = item.tableProp;
    for (let i = 0; i < filterArray[0].values.length; i++) {
      if (firstCol === 'date' && !tableList[i][firstCol]) {
        let firstObject = {
          [firstCol]: filterArray[0].values[i].ts,
        };
        Object.assign(tableList[i], firstObject);
      }
      if (firstCol === 'asset' && !tableList[i][firstCol]) {
        let firstObject = {
          [firstCol]: filterArray[0].values[i].id,
        };
        Object.assign(tableList[i], firstObject);
      }
      let itemObject = {
        [key]:
          filterArray[0].values[i].v != null
            ? Number(filterArray[0].values[i].v).toFixed(2)
            : '#N/A',
      };
      Object.assign(tableList[i], itemObject);
    }
  });
  return { cols, tableList };
};

export const calculateTotalAndAverageData = (handleData: any) => {
  let totalValue: number = 0;
  let availableLength: number = 0;
  let resultTotal: number | string = 0;
  let resultAverage: number | string = 0;
  handleData.values.forEach((item: any) => {
    if (item.v != null) {
      availableLength++;
    }
    totalValue = totalValue + Number(item.v ? item.v : 0);
  });
  resultTotal = availableLength > 0 ? Number(totalValue.toFixed(2)) : '#N/A';
  resultAverage =
    availableLength > 0
      ? (Number(resultTotal) / availableLength).toFixed(2)
      : '#N/A';
  return { resultTotal, resultAverage };
};

//combine the realTime Post Body
export const realTimeKPIPostBody = (assetId: string) => {
  let realTimeKpiPostData = {
    metadata: {
      filter: {
        assetId: assetId,
      },
    },
    details: [
      {
        entity: 'ENTT_ASSET',
        fields: [
          {
            code: REAL_TIME_KPIS_METRICS.HydraulicPressure,
            unit: KPI_UNIT.Bar,
          },
          {
            code: REAL_TIME_KPIS_METRICS.CurrentPayload,
            unit: KPI_UNIT.MetricTonne,
          },
          {
            code: REAL_TIME_KPIS_METRICS.CompactorFillingPercentage,
            // "unit": "UNIT_PERCENTAGE"
          },
          {
            code: REAL_TIME_KPIS_METRICS.OilLevelLow,
            // "unit": "UNIT_BOOLEAN"
          },
          {
            code: REAL_TIME_KPIS_METRICS.OilTemperature,
            unit: KPI_UNIT.DegreesCelsius,
          },
        ],
      },
    ],
  };

  return realTimeKpiPostData;
};

/**
 * [0] = x-value
 * [1] = y-value
 * [2] = information about the value limit
 */
export type KpiChartDataItem = [
  string,
  number | string | undefined,
  ValueLimitCheck | undefined
];

//export realTime kpi  chart data and handle it
export function realTimeKPIChartData(
  handleData: KpiDataField[],
  kpiType: typeof REAL_TIME_KPIS_METRICS[keyof typeof REAL_TIME_KPIS_METRICS],
  timeStamp: string
): KpiChartDataItem {
  if (kpiType === REAL_TIME_KPIS_METRICS.OilLevelLow) {
    let filterData = handleData.filter((item) => {
      return item.code === kpiType;
    });
    let chartDataItem: KpiChartDataItem = [timeStamp, undefined, undefined];
    let oilLevelMap: any = OIL_LEVELS_NUMBER_MAP;
    filterData[0].values.forEach((item) => {
      if (item.v != undefined) {
        chartDataItem[1] = oilLevelMap[item.v];
        chartDataItem[2] = item.lc ?? undefined;
      }
    });
    return chartDataItem;
  } else {
    let filterData = handleData.filter((item) => {
      return item.code === kpiType;
    });
    let chartDataItem: KpiChartDataItem = [timeStamp, undefined, undefined];
    filterData[0].values.forEach((item) => {
      if (item.v != undefined) {
        chartDataItem[1] = Number(item.v).toFixed(2);
        chartDataItem[2] = item.lc ?? undefined;
      }
    });
    return chartDataItem;
  }
}

export interface KpiLimitAndWarningInfo {
  lowLimit: number | undefined;
  highLimit: number | undefined;
  lowWarning: number | undefined;
  highWarning: number | undefined;
}

//export realTime kpi limit and warning info and handle chart data
export function realTimeKpiLimitAndWarningInfo(
  limitInfo: CustomizedKpiLimit[],
  kpiName: string
): KpiLimitAndWarningInfo {
  let limitSpecificInfo = limitInfo.filter((item) => {
    return item.propertyName === kpiName;
  });
  return {
    lowLimit: limitSpecificInfo.at(0)?.alarmLow ?? undefined,
    highLimit: limitSpecificInfo.at(0)?.alarmHigh ?? undefined,
    lowWarning: limitSpecificInfo.at(0)?.warningLow ?? undefined,
    highWarning: limitSpecificInfo.at(0)?.warningHigh ?? undefined,
  };
}

/**
 * Handle use case for value between limit & warning boundaries
 * 1) all limit & warning exist:
 *  - smalled then alarm low or bigger then alarm high: red
 *  - between alarm low and warning low or between warning high and alarm high: orange
 *  - between warning low and warning high: green
 * 2) left boundary alarm low and warning low missing: green
 * 3) right boundary alarm high and warning high missing: green
 * @param value
 * @param limitRange
 * @param defaultColor
 * @returns string of html hexcolor
 */
export const realTimeKpiColor = (
  value: number | string,
  limitRange: {
    lowLimit: any;
    highLimit: any;
    lowWarning: any;
    highWarning: any;
  },
  defaultColor?: string
): string => {
  let numberValue: number = Number(value);
  let resultColor: string = defaultColor
    ? defaultColor
    : COLORS.get('DEFAULT')!;

  if (numberValue < limitRange.lowLimit) {
    resultColor = COLORS.get('RED')!;
  }

  if (
    numberValue >= limitRange.lowLimit &&
    numberValue < limitRange.lowWarning
  ) {
    resultColor = COLORS.get('ORANGE')!;
  }

  if (
    numberValue >= limitRange.lowWarning &&
    numberValue < limitRange.highWarning
  ) {
    resultColor = COLORS.get('GREEN')!;
  }

  if (
    numberValue >= limitRange.highWarning &&
    numberValue < limitRange.highLimit
  ) {
    resultColor = COLORS.get('ORANGE')!;
  }

  if (numberValue >= limitRange.highLimit) {
    resultColor = COLORS.get('RED')!;
  }

  if (
    numberValue >= limitRange.lowWarning &&
    limitRange.highLimit == 0 &&
    limitRange.highWarning != 0
  ) {
    resultColor = COLORS.get('ORANGE')!;
  }

  if (
    numberValue >= limitRange.lowWarning &&
    limitRange.highLimit == 0 &&
    limitRange.highWarning == 0
  ) {
    resultColor = COLORS.get('GREEN')!;
  }

  return resultColor;
};

/**
 * Map kpi alarm with hex color
 */
const limitCheckToColorMap = new Map<ValueLimitCheck, string>([
  [ValueLimitCheck.WARN_LOW, getHexColor('ORANGE')],
  [ValueLimitCheck.WARN_HIGH, getHexColor('ORANGE')],
  [ValueLimitCheck.ALARM_LOW, getHexColor('RED')],
  [ValueLimitCheck.ALARM_HIGH, getHexColor('RED')],
  [ValueLimitCheck.OK, getHexColor('GREEN')],
]);

export function valueLimitCheckToColor(
  lc: ValueLimitCheck | null | undefined
): string {
  lc ??= ValueLimitCheck.OK;
  const color = limitCheckToColorMap.get(lc);
  if (!color) {
    throw new Error(`Invalid ValueLimitCheck '${lc}'`);
  }
  return color;
}

/**
 * Retrieve color for real time kpi
 * @param colorName
 * @returns string as hex color
 */
export function getHexColor(colorName: string): string {
  const color = COLORS.get(colorName);
  if (!color) {
    throw new Error(`Hex color for '${colorName}' has not been found.`);
  }
  return color;
}

export function oilLevelValueToTranslatedName(
  value: string | number | undefined
): string {
  const key = Object.entries(OIL_LEVELS_NUMBER_MAP).find(
    ([_entryKey, entryValue]) => Number(value) === entryValue
  )?.[0];
  return key != undefined ? i18n.tc(key) : '';
}
