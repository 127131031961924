export const PROD_CATA_CONF_LIST = [
  {
    label: 'prodCata.productModelName',
    prop: 'code',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'prodCata.modelNumber',
    prop: 'modelNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'prodCata.assetType',
    prop: 'assetTypeCode',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'prodCata.ofParts',
    prop: 'numberOfParts',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'prodCata.status',
    prop: 'lifeCycle',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'prodCata.action',
    prop: 'action',
    required: true,
    visible: true,
  },
];

export const PROD_CATA_MODEL_PARTS_LIST = [
  {
    label: 'prodCata.containedPart',
    prop: 'name',
    visible: true,
    multiLang: true,
  },
  {
    label: 'prodCata.partMaterialId',
    prop: 'partMaterialNumber',
    visible: true,
    multiLang: true,
  },
  {
    label: 'prodCata.partMaterialName',
    prop: 'partMaterialName',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: 'prodCata.partType',
    prop: 'partType',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: 'prodCata.obuInstance',
    prop: 'obuInstance',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: 'prodCata.maintainable',
    prop: 'isMaintainable',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: 'prodCata.lifetime',
    prop: 'lifeTime',
    visible: true,
    required: true,
    multiLang: true,
  },
  {
    label: 'prodCata.lifetimeUnit',
    prop: 'lifeTimeUnit',
    visible: true,
    required: true,
    multiLang: true,
  },
];

export const PROD_CATALOGUE_MODEL_PARTS_INFORMATIONS = [
  {
    label: 'prodCata.containedPart',
    prop: 'name',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'prodCata.partMaterialId',
    prop: 'partMaterialId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'prodCata.partMaterialName',
    prop: 'partMaterialName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'prodCata.partType',
    prop: 'partType',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'prodCata.obuInstance',
    prop: 'obuInstance',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'prodCata.maintainable',
    prop: 'isMaintainable',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'prodCata.lifetime',
    prop: 'lifeTime',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'prodCata.lifetimeUnit',
    prop: 'lifeTimeUnit',
    required: true,
    visible: true,
    sortable: 'custom',
  },
];

export const PROD_CATA_PARTS_LIST = [
  {
    label: 'prodCata.partMaterialId',
    prop: 'partMaterialNumber',
    required: true,
    visible: true,
  },
  {
    label: 'prodCata.partMaterialName',
    prop: 'name',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: 'prodCata.partType',
    prop: 'partType',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: 'prodCata.status',
    prop: 'lifeCycle',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: 'prodCata.action',
    prop: 'action',
    required: true,
    visible: true,
  },
];

export const PROD_CATA_APPROVE_LIST = [
  {
    label: 'prodCata.partMaterialId',
    prop: 'partMaterialNumber',
    required: true,
    visible: true,
  },
  {
    label: 'prodCata.partMaterialName',
    prop: 'name',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: 'prodCata.partType',
    prop: 'partType',
    required: true,
    visible: true,
    multiLang: true,
  },
  {
    label: 'prodCata.status',
    prop: 'lifeCycle',
    required: true,
    visible: true,
    multiLang: true,
  },
];
